import { DateTime } from "luxon";
import { useState } from "react";
import { TIME_ZONE } from "../constants";

const useMonthCalendar = ({
  initialMonth,
  initialYear,
}: {
  initialMonth?: number;
  initialYear?: number;
}) => {
  const dateTime = DateTime.now().setZone(TIME_ZONE);
  const [month, setMonth] = useState<number>(
    initialMonth ?? dateTime.get("month")
  );
  const [year, setYear] = useState<number>(initialYear ?? dateTime.get("year"));

  const calenderNext = () => {
    if (month < 12) {
      setMonth(month + 1);
      return { month: month + 1, year };
    } else {
      setMonth(1);
      setYear(year + 1);
      return { month: 1, year: year + 1 };
    }
  };

  const calenderBack = () => {
    if (month > 1) {
      setMonth(month - 1);
      return { month: month - 1, year };
    } else {
      setMonth(12);
      setYear(year - 1);
      return { month: 12, year: year - 1 };
    }
  };
  return { calenderBack, calenderNext, month, year, setMonth, setYear };
};

export default useMonthCalendar;
