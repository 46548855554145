export const CarotRight = ({
  pathFill = "white",
  size = { width: "24px", height: "24px" },
}: {
  pathFill?: string;
  size?: { width: string; height: string };
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g id="CaretRight">
        <path
          id="Vector"
          d="M9 4.5L16.5 12L9 19.5"
          stroke={pathFill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
