import { FC } from "react";

interface crossI {
  className?: string;
  size?: { width: string; height: string };
  stroke?: string;
}
const Cross: FC<crossI> = ({
  className,
  size = { width: "24px", height: "24px" },
  stroke = "#838383",
}) => {
  return (
    <>
      <svg
        className={className}
        width={size.width}
        height={size.height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 5.25L5.25 18.75"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 18.75L5.25 5.25"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
export default Cross;
