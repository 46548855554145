const debouncer = (wait: number, callback: (...args: any[]) => any) => {
  let lock = false;

  return (...args: any[]) => {
    if (!lock) {
      callback(...args);
      lock = true;
      setTimeout(() => {
        lock = false;
      }, wait);
    }
  };
};

export default debouncer;
