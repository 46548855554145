const Pen = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.71175 14.1686H2.93136L11.2975 5.80242L10.0779 4.5828L1.71175 12.949V14.1686ZM0 15.8803V12.2429L11.2975 0.966736C11.4687 0.809826 11.6577 0.688578 11.8645 0.60299C12.0714 0.517403 12.2889 0.474609 12.5171 0.474609C12.7454 0.474609 12.9665 0.517403 13.1804 0.60299C13.3944 0.688578 13.5799 0.816959 13.7368 0.988133L14.9136 2.18636C15.0848 2.34327 15.2096 2.5287 15.288 2.74267C15.3665 2.95664 15.4057 3.17061 15.4057 3.38458C15.4057 3.61281 15.3665 3.83034 15.288 4.03718C15.2096 4.24402 15.0848 4.43302 14.9136 4.6042L3.63746 15.8803H0ZM10.677 5.20331L10.0779 4.5828L11.2975 5.80242L10.677 5.20331Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Pen;
