import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HiCalendar } from "react-icons/hi";

import { TIME_ZONE } from "@/constants";

import { useSettingsStore } from "@/store/settings.state";
import { useSeniority } from "@/store/seniority.state";
import { useLocation } from "@/store/location.store";
import { useCalendar } from "@/store/calendar.state";

import Calendar from "@/components/Calender/Calendar";
import CalenderCard from "@/components/Calender/CalenderCard";
import CalendarSwitcher from "@/components/Calender/CalendarSwitcher";
import DayView from "@/components/Dashboard/DayView";
import Typography from "@/components/Typography";
import NavBar from "@/components/NavBar";
import ReleaseShiftReq from "@/components/Modals/ReleaseShiftReq";
import FinalisedRosterRelease from "@/components/Modals/FinalisedRosterRelease";

import SideBarLayout from "@/layouts/Sidebar";

import {
  useDeleteSchedulesDateMutation,
  useDeleteShiftsDateMutation,
  useGetCalendarQuery,
  useGetLocationsQuery,
} from "@/api/locationApi";
import { useGetShiftsByTimingQuery } from "@/api/shiftsApi";
import { useGetDayOffsQuery } from "@/api/dayOffApi";

import { formatDateAPI } from "@/utils/formatDate";
import {
  addRemoveDateRanges,
  decrementMonth,
  getDateFromHash,
  getHashFromDate,
  incrementMonth,
} from "@/utils/date";
import handleResponse from "@/utils/handleResponse";

import { useRunOnChangeExt } from "@/hooks/runOnChange";
import useKey from "@/hooks/useKey";
import useRightClick from "@/hooks/useRightClick";
import { useModal } from "@/hooks/useModal";

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeId: activeSeniorityId, setActiveId } = useSeniority();
  const { dashboard: dashboardSettings, setDashboard: setDashboardSettings } =
    useSettingsStore();

  const { month, setMonth, year, setYear } = useCalendar();

  const { activeId: activeLocationId, setActiveLocation } = useLocation();

  const { openModal } = useModal();

  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [columnType, setColumnType] = useState<"double" | "single">(
    dashboardSettings.columnType
  );
  const [activeDates, setActiveDates] = useState(
    searchParams.get("dateHash")
      ? [
          DateTime.fromJSDate(getDateFromHash(searchParams.get("dateHash")!))
            .setZone(TIME_ZONE)
            .toJSDate(),
        ]
      : [
          DateTime.fromObject(
            { day: 1, month, year },
            { zone: TIME_ZONE }
          ).toJSDate(),
        ]
  );

  const setDashboardParams = ({
    locationId,
    date,
    cType,
    seniority,
  }: {
    date?: Date;
    cType?: "double" | "single";
    seniority?: number;
    locationId?: string;
  }) => {
    setSearchParams({
      locationId: locationId ?? activeLocationId,
      seniority: seniority?.toString() ?? activeSeniorityId?.toString() ?? "1",
      dateHash: getHashFromDate(date ?? activeDates[0]),
      columnType: cType ?? columnType,
    });
  };

  const {
    isLoading,
    data: calendarData,
    refetch,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId }
  );

  const {
    isLoading: isShiftsByTimingLoading,
    isFetching: isShiftsByTimingFetching,
    data: shiftsByTiming,
  } = useGetShiftsByTimingQuery({ date: formatDateAPI(activeDates[0]) });

  const {
    data: dayOffData,
    isLoading: isDayOffLoading,
    isFetching: isDayOffFetching,
  } = useGetDayOffsQuery({ date: formatDateAPI(activeDates[0]) });

  const { isLoading: isLocationLoading, data: locationsData } =
    useGetLocationsQuery({
      seniority: 4,
      all: true,
    });

  const [deleteSchedules, { isLoading: isDeleteSchedulesLoading }] =
    useDeleteSchedulesDateMutation();
  const [deleteShifts, { isLoading: isDeleteShiftsLoading }] =
    useDeleteShiftsDateMutation();

  const {
    isKeyPressed: { Shift: isShiftPressed },
  } = useKey(calendarRef, ["Shift"]);

  useRightClick(calendarRef, () => {
    setActiveDates([activeDates[0]]);
  });

  useRunOnChangeExt(locationsData, () => {
    if (
      searchParams.get("locationId") &&
      searchParams.get("seniority") &&
      searchParams.get("dateHash") &&
      locationsData?.locations.length > 0
    ) {
      const dateTime = DateTime.fromJSDate(
        getDateFromHash(searchParams.get("dateHash") as string)
      ).setZone(TIME_ZONE);
      setMonth(dateTime.month);
      setYear(dateTime.year);
      setActiveId(Number(searchParams.get("seniority")));
      setActiveLocation(
        locationsData.locations?.find(
          (location: { _id: string }) =>
            location._id === (searchParams.get("locationId") as string)
        )
      );
      setColumnType(
        (searchParams.get("columnType") as "double" | "single") ??
          dashboardSettings.columnType
      );
    } else if (locationsData?.locations.length > 0) {
      setActiveLocation(locationsData.locations[0]);
      setDashboardParams({ locationId: locationsData.locations[0]._id });
    } else {
      // Throw warning that location is not found.
    }
  });

  // const [autoAssign] = useAutoAssignmentMonthMutation();

  const setActiveDateNxtMonth = (month: number, year: number) => {
    const newActiveDate = DateTime.fromObject(
      {
        day: 1,
        month,
        year,
      },
      { zone: TIME_ZONE }
    ).toJSDate();
    setActiveDates([newActiveDate]);
    setDashboardParams({ date: newActiveDate });
  };

  // const shouldDisplayRosterAutomation =
  //   calendarData &&
  //   calendarData.calendar.every((c: any) => {
  //     if (c.isClosed) {
  //       return true;
  //     }
  //     if (c.summary) {
  //       return true;
  //     }
  //     return false;
  //   });

  const getCalendarData = (date: Date) =>
    calendarData?.calendar.find((item: { date: string }) =>
      DateTime.fromJSDate(date)
        .setZone(TIME_ZONE)
        .equals(DateTime.fromISO(item.date).setZone(TIME_ZONE))
    );

  const activeDateSummary = getCalendarData(activeDates[0]);

  // const dispatch = useDispatch();

  return (
    <SideBarLayout
      isCalendarLoading={isLoading}
      type={"dashboard"}
      setParams={{
        dashboard: setDashboardParams,
        estimation: undefined,
        shiftPoints: undefined,
      }}
    >
      <div
        className="flex-grow flex flex-col items-center pl-10 pr-5 outline-none"
        tabIndex={-1}
        ref={calendarRef}
      >
        <NavBar setDashboardParams={setDashboardParams} />
        {isLocationLoading && (
          <div className="flex justify-center items-center flex-grow">
            <div>
              <InfinitySpin width="62%" color="#67823A" />
            </div>
          </div>
        )}
        {locationsData?.locations.length > 0 && activeLocationId !== "null" && (
          <div className="flex-grow flex w-full gap-5 h-0">
            <div className="flex-grow h-full pb-5 flex flex-col">
              <div className="flex w-full p-4 justify-between">
                <CalendarSwitcher
                  buttonClass="h-6 w-6 bg-primary text-secondary text-xl rounded-lg"
                  textClass="font-semibold whitespace-nowrap text-[2.8rem]"
                  disabledLeft={
                    isLoading ||
                    !activeLocationId ||
                    !activeSeniorityId ||
                    !calendarData
                  }
                  disabledRight={
                    isLoading ||
                    !activeLocationId ||
                    !activeSeniorityId ||
                    !calendarData
                  }
                  month={month}
                  year={year}
                  onNext={() => {
                    const newMonthYear = incrementMonth(month, year);
                    setMonth(newMonthYear.month);
                    if (newMonthYear.year !== year) {
                      setYear(newMonthYear.year);
                    }
                    setActiveDateNxtMonth(
                      newMonthYear.month,
                      newMonthYear.year
                    );
                  }}
                  onPrev={() => {
                    const newMonthYear = decrementMonth(month, year);
                    setMonth(newMonthYear.month);
                    if (newMonthYear.year !== year) {
                      setYear(newMonthYear.year);
                    }
                    setActiveDateNxtMonth(
                      newMonthYear.month,
                      newMonthYear.year
                    );
                  }}
                />
                <div className="flex items-center gap-x-3">
                  <button
                    className="whitespace-nowrap border-secondary border-2 text-secondary font-medium py-2.5 px-6 rounded-2xl"
                    onClick={() => {
                      openModal(
                        <FinalisedRosterRelease
                          onSubmit={async () => {
                            await refetch();
                          }}
                          calendarData={calendarData.calendar}
                        />
                      );
                    }}
                  >
                    Finalized Roster Release
                  </button>
                  {/* {shouldDisplayRosterAutomation ? (
                    <button
                      className="whitespace-nowrap border-secondary border-2 text-secondary font-medium py-2.5 px-6 rounded-2xl"
                      onClick={() => {
                        const autoAssignPromise = autoAssign({
                          location: activeLocationId,
                          seniority: Number(activeSeniorityId),
                          month,
                          year,
                        });
                        openModal(<StartAssignmentModal />);
                        autoAssignPromise
                          .then(() => {
                            dispatch(shiftsApi.util.resetApiState());
                            dispatch(requestsApi.util.resetApiState());
                            closeModal();
                            openModal(
                              <SuccessModal
                                message="Automatic estimation successful!"
                                title="Auto Assignment"
                                isLoading={false}
                              />
                            );
                            refetch();
                          })
                          .catch((err) => {
                            toast.error(
                              err?.response?.data?.message ??
                                "Something went wrong"
                            );
                          });
                      }}
                    >
                      Roster Automation
                    </button>
                  ) : null} */}
                  <button
                    disabled={
                      isLoading ||
                      !activeLocationId ||
                      !activeSeniorityId ||
                      !calendarData
                    }
                    className="whitespace-nowrap text-white bg-secondary font-medium py-3 px-7 rounded-2xl flex gap-5 items-center"
                    onClick={() => {
                      openModal(
                        <ReleaseShiftReq
                          onSubmit={async () => {
                            await refetch();
                          }}
                          calendarData={calendarData.calendar}
                          deadline={
                            calendarData.deadline
                              ? DateTime.fromISO(calendarData.deadline)
                                  .setZone(TIME_ZONE)
                                  .toJSDate()
                              : undefined
                          }
                        />
                      );
                    }}
                  >
                    <HiCalendar className="scale-150" />
                    Release Shift Request
                  </button>
                </div>
              </div>
              <div className="flex-grow overflow-auto small-scrollbar">
                <Calendar
                  month={month}
                  year={year}
                  className="!gap-6"
                  onDayRender={({ day: date, menuLoc }) => {
                    const dateTime =
                      DateTime.fromJSDate(date).setZone(TIME_ZONE);
                    const day = dateTime.toFormat("EEE");
                    const dateFormatted = dateTime.toFormat("dd");
                    const data = getCalendarData(date);
                    const dateHash = window.btoa(date.getTime().toString());
                    const summary = data?.summary;

                    return (
                      <CalenderCard
                        key={date.getTime()}
                        menuLoc={menuLoc}
                        onClick={() => {
                          if (isShiftPressed) {
                            setActiveDates(
                              addRemoveDateRanges(
                                dateTime.toJSDate(),
                                activeDates
                              )
                            );
                          } else {
                            setActiveDates([dateTime.toJSDate()]);
                          }

                          setDashboardParams({
                            date: dateTime.toJSDate(),
                          });
                        }}
                        skeleton={
                          isLoading || !activeLocationId || !activeSeniorityId
                        }
                        className={"col-span-1"}
                        isFinalized={data?.isFinalized}
                        isOpenRequest={data?.allowRequestForDay}
                        isActive={Boolean(
                          activeDates.find((aD) =>
                            dateTime.equals(
                              DateTime.fromJSDate(aD).setZone(TIME_ZONE)
                            )
                          )
                        )}
                        date={date}
                        monthName={dateFormatted}
                        isEstimated={!!data?.summary}
                        isDisabled={data?.isClosed}
                        url={`/dashboard/${dateHash}?locationId=${activeLocationId}&seniority=${activeSeniorityId}`}
                        day={day}
                        summary={summary}
                        deleteSchedules={async (
                          successFunction: () => void
                        ) => {
                          const response = await deleteSchedules({
                            locationId: activeLocationId,
                            seniority: activeSeniorityId,
                            dates: activeDates.map((date) =>
                              formatDateAPI(date)
                            ),
                          });
                          handleResponse(
                            response,
                            "Shift data wiped from date.",
                            successFunction
                          );
                        }}
                        deleteShifts={async (successFunction: () => void) => {
                          const response = await deleteShifts({
                            locationId: activeLocationId,
                            seniority: activeSeniorityId,
                            dates: activeDates.map((date) =>
                              formatDateAPI(date)
                            ),
                          });
                          handleResponse(
                            response,
                            "Shift data wiped from date.",
                            successFunction
                          );
                        }}
                        isDeleteSchedulesLoading={isDeleteSchedulesLoading}
                        isDeleteShiftsLoading={isDeleteShiftsLoading}
                        isMultiSelect={activeDates.length > 1}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <DayView
              setDashboardParams={setDashboardParams}
              setColumnType={(columnType: "single" | "double") => {
                setColumnType(columnType);
                setDashboardSettings({ columnType });
              }}
              columnType={columnType}
              shiftsByTiming={shiftsByTiming}
              dayOff={dayOffData}
              isLoading={isShiftsByTimingLoading || isDayOffLoading}
              isFetching={isShiftsByTimingFetching || isDayOffFetching}
              day={DateTime.fromJSDate(activeDates[0])
                .setZone(TIME_ZONE)
                .toFormat("EEE")}
              date={DateTime.fromJSDate(activeDates[0])
                .setZone(TIME_ZONE)
                .toFormat("dd")}
              dateStatus={
                !activeDateSummary?.allowRequestForDay &&
                !activeDateSummary?.isFinalized
                  ? "requests-not-released"
                  : activeDateSummary?.allowRequestForDay
                  ? "requests-released"
                  : "finalised"
              }
            />
          </div>
        )}
        {!isLocationLoading && !locationsData.locations.length ? (
          <div className="flex flex-col justify-center flex-grow w-full items-center">
            <Typography tag="h3" className="text-black1">
              No locations found. Please add a location to view calendar.
            </Typography>
          </div>
        ) : null}
      </div>
    </SideBarLayout>
  );
};

export default Dashboard;
