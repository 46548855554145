const Sort = ({
  type,
  pathFill,
}: {
  type: "up" | "down";
  pathFill: string;
}) => {
  if (type === "up") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
      >
        <path
          d="M0 7.94118L1.98529 2.64706H3.12353L5.10882 7.94118H4.02353L3.57353 6.67059H1.53529L1.11176 7.94118H0ZM1.85294 5.77059H3.22941L2.59412 3.78529H2.51471L1.85294 5.77059ZM6.19412 7.94118V6.93529L8.86765 3.6H6.3V2.64706H10.0324V3.65294L7.38529 6.98824H10.0588V7.94118H6.19412ZM3.70588 1.58824L5.29412 0L6.88235 1.58824H3.70588Z"
          fill={pathFill}
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
      >
        <path
          d="M0 5.96492L1.97368 0.631592H3.10526L5.07895 5.96492H4L3.55263 4.68492H1.52632L1.10526 5.96492H0ZM1.84211 3.77826H3.21053L2.57895 1.77826H2.5L1.84211 3.77826ZM6.15789 5.96492V4.95159L8.81579 1.59159H6.26316V0.631592H9.97368V1.64492L7.34211 5.00493H10V5.96492H6.15789ZM5.26316 8.63159L3.68421 7.03159H6.84211L5.26316 8.63159Z"
          fill={pathFill}
        />
      </svg>
    );
  }
};

export default Sort;
