const Settings = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M5.28738 15.0182L4.99766 12.6889C4.84073 12.6282 4.69285 12.5554 4.55403 12.4705C4.4152 12.3856 4.2794 12.2946 4.14661 12.1975L1.99182 13.1074L0 9.64983L1.86507 8.23038C1.853 8.14546 1.84696 8.06357 1.84696 7.98471V7.49336C1.84696 7.41451 1.853 7.33262 1.86507 7.24769L0 5.82825L1.99182 2.37063L4.14661 3.28053C4.2794 3.18347 4.41822 3.09248 4.56308 3.00756C4.70794 2.92263 4.8528 2.84984 4.99766 2.78918L5.28738 0.459839H9.27102L9.56074 2.78918C9.71767 2.84984 9.86555 2.92263 10.0044 3.00756C10.1432 3.09248 10.279 3.18347 10.4118 3.28053L12.5666 2.37063L14.5584 5.82825L12.6933 7.24769C12.7054 7.33262 12.7114 7.41451 12.7114 7.49336V7.98471C12.7114 8.06357 12.6994 8.14546 12.6752 8.23038L14.5403 9.64983L12.5485 13.1074L10.4118 12.1975C10.279 12.2946 10.1402 12.3856 9.99532 12.4705C9.85046 12.5554 9.7056 12.6282 9.56074 12.6889L9.27102 15.0182H5.28738ZM7.31541 10.2868C8.01557 10.2868 8.61311 10.0381 9.10805 9.54064C9.60299 9.04323 9.85046 8.44269 9.85046 7.73904C9.85046 7.03538 9.60299 6.43485 9.10805 5.93744C8.61311 5.44002 8.01557 5.19132 7.31541 5.19132C6.60319 5.19132 6.00262 5.44002 5.51372 5.93744C5.02482 6.43485 4.78037 7.03538 4.78037 7.73904C4.78037 8.44269 5.02482 9.04323 5.51372 9.54064C6.00262 10.0381 6.60319 10.2868 7.31541 10.2868Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Settings;
