import { TIME_ZONE, TimeBlock, TIMING_LIST } from "@/constants";
import { DateTime } from "luxon";

export const getTimeBlock = (from: string): TimeBlock => {
  const fromTime = DateTime.fromISO(from).setZone(TIME_ZONE);

  return (
    TIMING_LIST.filter((tList) => tList.type === "normal").find((tList) => {
      return tList.time?.some(
        (t) =>
          fromTime >=
            DateTime.fromObject(
              {
                year: fromTime.year,
                month: fromTime.month,
                day: fromTime.day,
                hour: t.from.hour,
                minute: t.from.min,
              },
              { zone: TIME_ZONE }
            ) &&
          fromTime <=
            DateTime.fromObject(
              {
                year: fromTime.year,
                month: fromTime.month,
                day: fromTime.day,
                hour: t.to.hour,
                minute: t.to.min,
              },
              { zone: TIME_ZONE }
            )
      );
    })?.key as TimeBlock ?? "morning"
  );
};
