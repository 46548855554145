import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  tagTypes: ["Calender"],
  endpoints: (builder) => ({
    getLeaves: builder.query<any, any>({
      query: ({ locationId, month, year }) => ({
        url: `location/${locationId}/leaves?month=${month}&year=${year}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getCalendar: builder.query<any, any>({
      query: ({ locationId, month, year, seniority }) => ({
        url: `/location/calendar/${locationId}?month=${month}&year=${year}&seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Calender"],
    }),
    getLocations: builder.query<any, any>({
      query: ({ seniority, all }) => ({
        url: `/location?seniority=${seniority}&all=${all}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    copySettings: builder.mutation<any, any>({
      query: ({ locationId, seniority, from, to }) => ({
        url: `/location/${locationId}/copy`,
        method: "POST",
        body: {
          seniority,
          from,
          to,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateLocation: builder.mutation<any, any>({
      query: ({ locationId, label, seniorities, groups, shortLabel }) => ({
        url: `/location/${locationId}`,
        method: "PATCH",
        body: {
          label,
          seniorities,
          groups,
          shortLabel,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteShiftsDate: builder.mutation<any, any>({
      query: ({ locationId, seniority, dates }) => ({
        url: `/location/${locationId}/shifts/delete`,
        method: "POST",
        body: {
          seniority,
          dates,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteSchedulesDate: builder.mutation<any, any>({
      query: ({ locationId, seniority, dates }) => ({
        url: `/location/${locationId}/schedules/delete`,
        method: "POST",
        body: {
          seniority,
          dates,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetLeavesQuery,
  useGetCalendarQuery,
  useGetLocationsQuery,
  useCopySettingsMutation,
  useUpdateLocationMutation,
  useDeleteSchedulesDateMutation,
  useDeleteShiftsDateMutation,
} = locationApi;
