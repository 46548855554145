import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const shiftsApi = createApi({
  reducerPath: "shiftsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  tagTypes: ["Shifts", "ShiftsByTiming"],
  endpoints: (builder) => ({
    getShifts: builder.query<any, any>({
      queryFn: async (
        { locationId, date, seniority }: any,
        api: BaseQueryApi,
        extraOptions: object,
        baseQuery: (arg: string | FetchArgs) => any
      ) => {
        const response = await baseQuery({
          url: `location/shift?date=${date}&seniority=${seniority}${
            locationId ? `&locationId=${locationId}` : ""
          }`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        if (response.data) {
          return { data: response.data };
        } else if (response.error.data.message === "No shifts found.") {
          return { data: {} };
        }
        return { error: response.error };
      },
      providesTags: ["Shifts"],
    }),
    getShiftsByTiming: builder.query<any, any>({
      queryFn: async (
        { date }: any,
        api: BaseQueryApi,
        extraOptions: object,
        baseQuery: (arg: string | FetchArgs) => any
      ) => {
        const response = await baseQuery({
          url: `location/shift/all/timing?date=${date}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        });
        if (response.data) {
          return { data: response.data };
        } else if (response.error.data.message === "No shifts found.") {
          return { data: [] };
        }
        return { error: response.error };
      },
      providesTags: ["ShiftsByTiming"],
    }),
    getShiftsByMonth: builder.query<any, any>({
      query: ({ month, year, seniority, numDatesPage, page }) => ({
        url: `shift/month?month=${month}&year=${year}${
          seniority ? `seniority=${seniority}` : ""
        }${numDatesPage ? `numDatesPage=${numDatesPage}` : ""}${
          page ? `page=${page}` : ""
        }`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createShift: builder.mutation<any, any>({
      query: ({ locationId, date, seniority, totalDoctorsRequired, time, type }) => ({
        url: `location/shift/${locationId}`,
        method: "POST",
        body: {
          date,
          time,
          type,
          seniority,
          totalDoctorsRequired,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateShift: builder.mutation<any, any>({
      query: ({ shiftId, totalDoctorsRequired, time, type }) => ({
        url: `location/shift/${shiftId}`,
        method: "PATCH",
        body: {
          totalDoctorsRequired,
          time,
          type,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteShift: builder.mutation<any, any>({
      query: ({ shiftId }) => ({
        url: `location/shift/${shiftId}`,
        method: "DELETE",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createSlotFilter: builder.mutation<any, any>({
      query: ({ slotIndex, shiftId, payload }) => ({
        url: `shift/slot/filter`,
        method: "POST",
        body: { slotIndex, shiftId, payload },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateSlotFilter: builder.mutation<any, any>({
      query: ({ slotFilterId, scheduleId, payload }) => ({
        url: `shift/update/slot/filter`,
        method: "POST",
        body: { slotFilterId, ...(scheduleId ? { scheduleId } : {}), payload },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteSlotFilter: builder.mutation<any, any>({
      query: ({ slotFilterId, shiftId, slotIndex }) => ({
        url: `shift/delete/slot/filter`,
        method: "POST",
        body: { slotFilterId, shiftId, slotIndex },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateSlotLabels: builder.mutation<any, any>({
      query: ({ slotIndex, shiftId, labels }) => ({
        url: `shift/update/slot/labels`,
        method: "POST",
        body: { slotIndex, shiftId, labels },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetShiftsQuery,
  useLazyGetShiftsQuery,
  useGetShiftsByTimingQuery,
  useGetShiftsByMonthQuery,
  useCreateShiftMutation,
  useUpdateShiftMutation,
  useDeleteShiftMutation,
  useCreateSlotFilterMutation,
  useUpdateSlotFilterMutation,
  useDeleteSlotFilterMutation,
  useUpdateSlotLabelsMutation,
  usePrefetch,
} = shiftsApi;
