const List = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.87247 7.00628H13.538V5.14009H8.87247V7.00628ZM8.87247 12.6049H13.538V10.7387H8.87247V12.6049ZM6.07318 7.93938C6.58639 7.93938 7.02572 7.75665 7.39118 7.39118C7.75665 7.02572 7.93938 6.58639 7.93938 6.07318C7.93938 5.55998 7.75665 5.12065 7.39118 4.75519C7.02572 4.38972 6.58639 4.20699 6.07318 4.20699C5.55998 4.20699 5.12065 4.38972 4.75519 4.75519C4.38972 5.12065 4.20699 5.55998 4.20699 6.07318C4.20699 6.58639 4.38972 7.02572 4.75519 7.39118C5.12065 7.75665 5.55998 7.93938 6.07318 7.93938ZM6.07318 13.538C6.58639 13.538 7.02572 13.3552 7.39118 12.9898C7.75665 12.6243 7.93938 12.185 7.93938 11.6718C7.93938 11.1586 7.75665 10.7192 7.39118 10.3538C7.02572 9.9883 6.58639 9.80557 6.07318 9.80557C5.55998 9.80557 5.12065 9.9883 4.75519 10.3538C4.38972 10.7192 4.20699 11.1586 4.20699 11.6718C4.20699 12.185 4.38972 12.6243 4.75519 12.9898C5.12065 13.3552 5.55998 13.538 6.07318 13.538ZM2.3408 17.2703C1.8276 17.2703 1.38827 17.0876 1.0228 16.7221C0.657341 16.3567 0.474609 15.9173 0.474609 15.4041V2.3408C0.474609 1.8276 0.657341 1.38827 1.0228 1.0228C1.38827 0.657341 1.8276 0.474609 2.3408 0.474609H15.4041C15.9173 0.474609 16.3567 0.657341 16.7221 1.0228C17.0876 1.38827 17.2703 1.8276 17.2703 2.3408V15.4041C17.2703 15.9173 17.0876 16.3567 16.7221 16.7221C16.3567 17.0876 15.9173 17.2703 15.4041 17.2703H2.3408ZM2.3408 15.4041H15.4041V2.3408H2.3408V15.4041Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default List