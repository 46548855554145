const Calendar2 = ({
  pathFill,
  size = { height: "18", width: "14" },
}: {
  pathFill: string;
  size?: { height: string; width: string };
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 68 76"
      fill="none"
    >
      <path
        d="M22.6667 54.7778C20.0222 54.7778 17.787 53.8648 15.9611 52.0389C14.1352 50.213 13.2222 47.9778 13.2222 45.3333C13.2222 42.6889 14.1352 40.4537 15.9611 38.6278C17.787 36.8019 20.0222 35.8889 22.6667 35.8889C25.3111 35.8889 27.5463 36.8019 29.3722 38.6278C31.1981 40.4537 32.1111 42.6889 32.1111 45.3333C32.1111 47.9778 31.1981 50.213 29.3722 52.0389C27.5463 53.8648 25.3111 54.7778 22.6667 54.7778ZM7.55556 75.5556C5.47778 75.5556 3.69907 74.8157 2.21944 73.3361C0.739815 71.8565 0 70.0778 0 68V15.1111C0 13.0333 0.739815 11.2546 2.21944 9.775C3.69907 8.29537 5.47778 7.55556 7.55556 7.55556H11.3333V0H18.8889V7.55556H49.1111V0H56.6667V7.55556H60.4444C62.5222 7.55556 64.3009 8.29537 65.7806 9.775C67.2602 11.2546 68 13.0333 68 15.1111V68C68 70.0778 67.2602 71.8565 65.7806 73.3361C64.3009 74.8157 62.5222 75.5556 60.4444 75.5556H7.55556ZM7.55556 68H60.4444V30.2222H7.55556V68ZM7.55556 22.6667H60.4444V15.1111H7.55556V22.6667Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Calendar2;
