import { useEffect, useRef } from "react";

import { ROW_WIDTH } from "@/constants";
import { SetTableParamsArgs } from "@/context/TableViewContext";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

const useSlidingAnimation = ({
  tableRef,
  dates,
  monthData,
  overlap,
  schedulesByDoctor,
  shiftsByMonth,
  dayOffsByMonth,
}: {
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
  dates: Date[];
  monthData: {
    prev: {
      daysInMonth: number;
      label: string;
    };
    current: {
      daysInMonth: number;
      label: string;
    };
  };
  overlap: number;
  schedulesByDoctor: any;
  shiftsByMonth: any;
  dayOffsByMonth: any;
}) => {
  const {
    monthRef,
    monthLabel,
    setMonthLabel,
    setTableParams,
    calenderBack,
    calenderNext,
    tableState,
    type,
    setType,
  } = useTableViewContext();

  const horizontScroll = useRef<{
    calenderBack: () => { month: number; year: number };
    calendarNext: () => {
      month: number;
      year: number;
    };
    setTableParams: (args: SetTableParamsArgs) => void;
    isRunOnce: boolean;
    datesLength: number;
    monthData: {
      prev: { daysInMonth: number; label: string };
      current: { daysInMonth: number; label: string };
    };
    overlap: number;
    type: "increment" | "decrement";
    tableState: "location" | "requests" | "users";
    monthLabel: {
      animated: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
      stationary: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
    };
  }>({
    calenderBack: calenderBack,
    calendarNext: calenderNext,
    setTableParams: setTableParams,
    isRunOnce: false,
    datesLength: dates.length,
    monthData,
    tableState: "location",
    overlap: overlap,
    type: "decrement",
    monthLabel,
  });

  horizontScroll.current.calendarNext = calenderNext;
  horizontScroll.current.calenderBack = calenderBack;
  horizontScroll.current.setTableParams = setTableParams;
  horizontScroll.current.monthData = monthData;
  horizontScroll.current.overlap = overlap;
  horizontScroll.current.datesLength = dates.length;
  horizontScroll.current.type = type;
  horizontScroll.current.monthLabel = monthLabel;
  horizontScroll.current.tableState = tableState;

  useEffect(() => {
    if (
      monthRef &&
      tableRef.current &&
      shiftsByMonth &&
      dayOffsByMonth &&
      monthRef.current &&
      !horizontScroll.current.isRunOnce
    ) {
      tableRef.current.scrollLeft =
        (ROW_WIDTH[horizontScroll.current.tableState].value + 2) *
          horizontScroll.current.overlap +
        4;
      const handler = () => {
        if (
          tableRef.current &&
          monthRef.current.animated &&
          monthRef.current.stationary
        ) {
          const overlapLength =
            (ROW_WIDTH[horizontScroll.current.tableState].value + 2) *
              horizontScroll.current.overlap +
            4;
          const fullLength =
            (ROW_WIDTH[horizontScroll.current.tableState].value + 2) *
            horizontScroll.current.datesLength;

          if (
            tableRef.current.scrollLeft >= 0 &&
            tableRef.current.scrollLeft < overlapLength
          ) {
            if (
              horizontScroll.current.monthLabel.animated.type !== "current" ||
              !horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                ...mL,
                animated: { ...mL.animated, type: "current", show: true },
              }));
            }
            //
            if (
              tableRef.current.scrollLeft >= 0 &&
              tableRef.current.scrollLeft <
                overlapLength -
                  (horizontScroll.current.monthData.prev.label.length * 45 + 88)
            ) {
              if (
                horizontScroll.current.monthLabel.animated.hasArrows ||
                horizontScroll.current.monthLabel.stationary.type !== "prev" ||
                !horizontScroll.current.monthLabel.stationary.show
              ) {
                setMonthLabel((mL) => ({
                  stationary: { ...mL.stationary, type: "prev", show: true },
                  animated: { ...mL.animated, hasArrows: false },
                }));
              }
            } else {
              if (
                !horizontScroll.current.monthLabel.animated.hasArrows ||
                horizontScroll.current.monthLabel.stationary.type !==
                  "current" ||
                horizontScroll.current.monthLabel.stationary.show
              ) {
                setMonthLabel((mL) => ({
                  stationary: {
                    ...mL.stationary,
                    type: "current",
                    show: false,
                  },
                  animated: { ...mL.animated, hasArrows: true },
                }));
              }
            }
            monthRef.current.animated.style.left = `${
              -1 * (tableRef.current.scrollLeft - overlapLength)
            }px`;
          }

          if (
            tableRef.current.scrollLeft >= overlapLength &&
            tableRef.current.scrollLeft <
              fullLength - overlapLength - tableRef.current.clientWidth
          ) {
            if (
              horizontScroll.current.monthLabel.stationary.type !== "current" ||
              !horizontScroll.current.monthLabel.stationary.show ||
              horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                animated: { ...mL.animated, show: false },
                stationary: { ...mL.stationary, type: "current", show: true },
              }));
            }
          }

          if (
            tableRef.current.scrollLeft >=
              fullLength - overlapLength - tableRef.current.clientWidth &&
            tableRef.current.scrollLeft <
              fullLength - tableRef.current.clientWidth
          ) {
            if (
              horizontScroll.current.monthLabel.animated.type !== "next" ||
              !horizontScroll.current.monthLabel.animated.show
            ) {
              setMonthLabel((mL) => ({
                ...mL,
                animated: { ...mL.animated, type: "next", show: true },
              }));
            }

            if (
              tableRef.current.scrollLeft >=
                fullLength -
                  tableRef.current.clientWidth -
                  (horizontScroll.current.monthData.current.label.length * 45 +
                    200) &&
              tableRef.current.scrollLeft <
                fullLength - tableRef.current.clientWidth
            ) {
              if (horizontScroll.current.monthLabel.stationary.show) {
                setMonthLabel((mL) => ({
                  ...mL,
                  stationary: { ...mL.stationary, show: false },
                }));
              }
            } else {
              if (!horizontScroll.current.monthLabel.stationary.show) {
                setMonthLabel((mL) => ({
                  ...mL,
                  stationary: { ...mL.stationary, show: true },
                }));
              }
            }

            monthRef.current.animated.style.left = `${
              fullLength - overlapLength - tableRef.current.scrollLeft
            }px`;
          }

          if (
            tableRef.current.scrollLeft <
              (fullLength - tableRef.current.clientWidth) / 2 &&
            horizontScroll.current.type === "increment"
          ) {
            setType("decrement");
          }
          if (
            tableRef.current.scrollLeft >
              (fullLength - tableRef.current.clientWidth) / 2 &&
            horizontScroll.current.type === "decrement"
          ) {
            setType("increment");
          }
          if (tableRef.current && tableRef.current.scrollLeft < 1) {
            tableRef.current.style.overflow = "hidden";
            const { month, year } = horizontScroll.current.calenderBack();
            horizontScroll.current.setTableParams({
              month_: month,
              year_: year,
            });
            setType("increment");
            tableRef.current.scrollLeft =
              (ROW_WIDTH[horizontScroll.current.tableState].value + 2) *
              horizontScroll.current.monthData.prev.daysInMonth;

            setMonthLabel({
              animated: { type: "current", hasArrows: true, show: false },
              stationary: { type: "current", hasArrows: true, show: true },
            });

            setTimeout(() => {
              tableRef.current!.style.overflow = "";
            }, 300);
          } else if (
            tableRef.current.scrollLeft >=
            fullLength - tableRef.current.clientWidth
          ) {
            tableRef.current.style.overflow = "hidden";
            setType("decrement");
            const { month, year } = horizontScroll.current.calendarNext();
            horizontScroll.current.setTableParams({
              month_: month,
              year_: year,
            });
            tableRef.current.scrollLeft = overlapLength;

            setMonthLabel({
              animated: { type: "current", hasArrows: true, show: false },
              stationary: { type: "current", hasArrows: true, show: true },
            });

            setTimeout(() => {
              tableRef.current!.style.overflow = "";
            }, 300);
          }
        }
      };
      tableRef.current.addEventListener("scroll", handler);
      horizontScroll.current.isRunOnce = true;
    }
  }, [
    dayOffsByMonth,
    shiftsByMonth,
    monthRef,
    tableRef,
    setMonthLabel,
    setType,
    tableState,
  ]);
};

export default useSlidingAnimation;
