import { TIME_ZONE } from "@/constants";
import { decrementMonth, incrementMonth } from "@/utils/date";
import { generateDatesMonth } from "@/utils/generateDates";
import { DateTime } from "luxon";

export const useGetDates = ({
  month,
  year,
  overlap,
}: {
  month: number;
  year: number;
  overlap: number;
}) => {
  const previousMonth = decrementMonth(month, year);
  const nextMonth = incrementMonth(month, year);

  const previousDateTime = DateTime.fromObject(
    { day: 1, ...previousMonth },
    { zone: TIME_ZONE }
  );

  const currentDateTime = DateTime.fromObject(
    { day: 1, month, year },
    { zone: TIME_ZONE }
  );

  const previousMonthDates = generateDatesMonth(
    previousMonth.month,
    previousMonth.year
  );

  const dates = [
    previousMonthDates.slice(previousMonthDates.length - overlap),
    generateDatesMonth(month, year),
    generateDatesMonth(nextMonth.month, nextMonth.year).slice(0, overlap),
  ];

  return {
    dates: dates.flat(),
    monthData: {
      prev: {
        daysInMonth: previousDateTime.daysInMonth! as number,
        label: previousDateTime.toFormat("LLLL"),
      },
      current: {
        daysInMonth: currentDateTime.daysInMonth! as number,
        label: currentDateTime.toFormat("LLLL"),
      },
    },
  };
};
