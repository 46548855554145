import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import "@splidejs/react-splide/css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import "react-responsive-modal/styles.css";
import "rc-slider/assets/index.css";

import "./globals.css";

import App from "./App.tsx";

import { rosterApi } from "./api/rosterApi.ts";
import { organisationApi } from "./api/organisationApi.ts";
import { shiftsApi } from "./api/shiftsApi.ts";
import { groupApi } from "./api/groupApi.ts.ts";
import { doctorApi } from "./api/doctorApi.ts";
import { locationApi } from "./api/locationApi.ts";
import { requestsApi } from "./api/requestsApi.ts";
import { preferencesApi } from "./api/preferencesApi.ts";
import { dayOffApi } from "./api/dayOffApi.ts";

import * as Sentry from "@sentry/react";

export const store = configureStore({
  reducer: {
    [organisationApi.reducerPath]: organisationApi.reducer,
    [rosterApi.reducerPath]: rosterApi.reducer,
    [shiftsApi.reducerPath]: shiftsApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [requestsApi.reducerPath]: requestsApi.reducer,
    [preferencesApi.reducerPath]: preferencesApi.reducer,
    [dayOffApi.reducerPath]: dayOffApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(organisationApi.middleware)
      .concat(rosterApi.middleware)
      .concat(shiftsApi.middleware)
      .concat(groupApi.middleware)
      .concat(doctorApi.middleware)
      .concat(locationApi.middleware)
      .concat(requestsApi.middleware)
      .concat(preferencesApi.middleware)
      .concat(dayOffApi.middleware),
});

setupListeners(store.dispatch);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: import.meta.env.VITE_NODE_ENV !== "dev",
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
