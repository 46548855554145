import { useMemo } from "react";
import { DateTime } from "luxon";

import { Shift } from "@/interface/shift";
import { Key, TIME_ZONE, TIMING_LIST } from "@/constants";
import { Doctor } from "@/interface/doctor";

type GroupedShifts = {
  key: Key;
  shifts?: Shift[];
  doctors?: Doctor[];
  label: string;
}[];

const useGroupShifts = ({
  shifts,
  dayOff,
}: {
  shifts: Shift[];
  dayOff: { doctors: Doctor[] };
}): GroupedShifts => {
  return useMemo(() => {
    const groupedShifts: GroupedShifts = [];

    if (shifts.length > 0) {
      TIMING_LIST.forEach((timeBlock) => {
        shifts.forEach((shift) => {
          const index = groupedShifts.findIndex(
            (gS) => gS.key === timeBlock.key
          );

          if (shift.type === timeBlock.type) {
            if (timeBlock.type !== "normal") {
              if (index !== -1) {
                groupedShifts[index].shifts!.push(shift);
              } else {
                groupedShifts.push({
                  shifts: [shift],
                  label: timeBlock.label,
                  key: timeBlock.key,
                });
              }
            } else {
              const fromTime = DateTime.fromISO(shift.time.from).setZone(
                TIME_ZONE
              );
              if (
                timeBlock.time?.some(
                  (t) =>
                    fromTime >=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.from.hour,
                          minute: t.from.min,
                        },
                        { zone: TIME_ZONE }
                      ) &&
                    fromTime <=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.to.hour,
                          minute: t.to.min,
                        },
                        { zone: TIME_ZONE }
                      )
                )
              ) {
                if (index !== -1) {
                  groupedShifts[index].shifts!.push(shift);
                } else {
                  groupedShifts.push({
                    shifts: [shift],
                    label: timeBlock.label,
                    key: timeBlock.key,
                  });
                }
              }
            }
          }
        });
      });
    }

    if (dayOff?.doctors.length > 0) {
      groupedShifts.push({
        doctors: dayOff.doctors,
        label: "Day Off",
        key: "dayOff",
      });
    }

    return groupedShifts;
  }, [shifts, dayOff?.doctors]);
};

export default useGroupShifts;
