import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import { TIME_ZONE } from "@/constants";
import api from "@/api/api";
import { useProfile } from "@/store/profile.state";
import { useSeniority } from "@/store/seniority.state";
import defaultAvater from "@/assets/images/avatar-default-light.webp";

import Typography from "../Typography/index";
import NavTab, { getSenioritiesNavTabs } from "../NavTabs";
import Table from "../Icons/Table";

const NavBar = ({
  setDashboardParams,
}: {
  setDashboardParams: (input: {
    date?: Date;
    cType?: "double" | "single";
    seniority?: number;
  }) => void;
}) => {
  const { role, username } = useProfile();
  const [hide, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setHide(false);
      });
    }
  }, [hide]);

  const {
    activeId: activeSeniorityId,
    setActiveId,
    disabledSeniorities,
  } = useSeniority();

  const { fetchFn: uploadAvatar } = api.auth.useUploadAvatar();
  const { setProfilePhoto, profilePhoto } = useProfile();
  const navigate = useNavigate();

  return (
    <div className="w-full pt-4 pb-6 py-3">
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <div
            className={"font-medium text-[20px] text-black uppercase font"}
            style={{ fontFamily: "LemonMilk" }}
          >
            Welcome Back!
          </div>
          <Typography tag={"span"} className={"font-bold text-xs text-black3"}>
            {DateTime.now().setZone(TIME_ZONE).toFormat("dd MMMM, y")}
          </Typography>
        </div>
        <NavTab
          tabs={getSenioritiesNavTabs(disabledSeniorities)}
          activeId={activeSeniorityId ?? 1}
          tabType="neumorphism"
          onChange={(id) => {
            setActiveId(id);
            setDashboardParams({ seniority: id });
          }}
        />
        <div className="flex gap-x-2">
          <div className="flex items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/table");
              }}
            >
              <Table pathFill={"#ADC386"} rectFill="#FFFDF7" />
            </div>
          </div>
          <div className="flex gap-x-2 relative">
            {!hide ? (
              <img
                src={profilePhoto === "" ? defaultAvater : profilePhoto}
                className="h-12 w-12 rounded-full "
                alt=""
              />
            ) : null}
            <input
              type="file"
              className="absolute top-0 left-0 h-12 w-12 hover:cursor-pointer opacity-0"
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (!file) return;

                const formData = new FormData();
                formData.append("image", file);
                const upload = await uploadAvatar({
                  data: formData,
                });

                const { isSuccess, error, data } = upload.unwrap();

                try {
                  if (isSuccess) {
                    toast.success("Avatar uploaded successfully!");
                    setProfilePhoto(data?.profilePicture?.url ?? "");
                    setHide(true);
                  }
                } catch (err) {
                  toast.error(
                    error?.response?.data?.message ?? "Something went wrong!"
                  );
                }
              }}
            />
            <div className="flex flex-col justify-around">
              <Typography tag="span" className="font-semibold">
                {username}
              </Typography>
              <Typography tag="small" className="text-gray-400 font-semibold">
                {role}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
