const Help = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M6.3175 6.25C6.49383 5.74875 6.84186 5.32608 7.29997 5.05685C7.75807 4.78762 8.29667 4.6892 8.82038 4.77903C9.34409 4.86886 9.81911 5.14114 10.1613 5.54765C10.5035 5.95415 10.6908 6.46864 10.69 7C10.69 8.5 8.44 9.25 8.44 9.25M8.5 12.25H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
        stroke={pathFill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Help