import { useGetLocationsQuery } from "@/api/locationApi";
import { useLocation } from "@/store/location.store";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { useState } from "react";
import CarotTwo from "../Icons/CarotTwo";
import ClockThree from "../Icons/ClockThree";
import SearchTwo from "../Icons/SearchTwo";
import { SENIORITIES } from "@/constants";
import { useSeniority } from "@/store/seniority.state";
import { element } from "prop-types";
import DualArrow from "../Icons/DualArrow";
import Cursor from "../Icons/Cursor";
import Settings from "../Icons/Settings";
import Menu from "../Icons/Menu";
import Eraser from "../Icons/Eraser";
import Pen from "../Icons/Pen";
import Help from "../Icons/Help";
import List from "../Icons/List";

const MenuOptions = () => {
  const { data: locationsData } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const {
    activeId: activeLocationId,
    setActiveLocation,
    activeLocation,
  } = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const {
    isDayOffFilter,
    setIsDayOffFilter,
    isShiftTimeToggle,
    setIsShiftTimeToggle,
    isLocationFilter,
    setIsLocationFilter,
    setTableParams,
    setSearchQuery,
    searchQuery,
    setDoctorFilter,
    menuOptions,
    setMenuOptions,
  } = useTableViewContext();

  const menuButtons: {
    element: JSX.Element;
    handleClick: () => void;
    isClicked: boolean;
    expandedElement?: JSX.Element;
  }[] = [
    {
      element: (
        <div
          className={`text-[11.4px] font-bold ${
            isDayOffFilter ? "text-white" : "text-green14"
          }`}
        >
          off
        </div>
      ),
      handleClick: () => {
        if (isLocationFilter) {
          setIsDayOffFilter(!isDayOffFilter);
          setTableParams({
            isDayOffFilter_: !isDayOffFilter,
          });
        }
      },
      isClicked: isDayOffFilter,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <ClockThree isActive={isShiftTimeToggle} />
        </div>
      ),
      handleClick: () => {
        setIsShiftTimeToggle(!isShiftTimeToggle);
        setTableParams({
          isShiftTimeToggle_: !isShiftTimeToggle,
        });
      },
      isClicked: isShiftTimeToggle,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <DualArrow pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Dual Arrow Clicked");
      },
      isClicked: false,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <Cursor pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Cursor Clicked");
      },
      isClicked: false,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <Settings pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Settings Clicked");
      },
      isClicked: false,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <List pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Menu Clicked");
      },
      isClicked: false,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <Eraser pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Eraser Clicked");
      },
      isClicked: false,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <Pen pathFill={menuOptions.edit ? "white" : "#2C5339"} />
        </div>
      ),
      handleClick: () => {
        setMenuOptions({
          ...menuOptions,
          edit: !menuOptions.edit,
        });
      },
      isClicked: menuOptions.edit,
      expandedElement: <div className="text-center text-white">fill</div>,
    },
    {
      element: (
        <div className="h-fit w-fit">
          <Help pathFill={"#2C5339"} />
        </div>
      ),
      handleClick: () => {
        console.log("Help Clicked");
      },
      isClicked: false,
    },
  ];

  return (
    <div className="w-full h-[100px] py-2 pr-2 items-center bg-gray4">
      <div className="flex justify-between">
        <div className="flex gap-1 h-[55px]">
          <div
            className="rounded-r-xl bg-green11 flex items-center relative transition-[width] duration-500 shadow-xl"
            style={{ width: isDrawerOpen ? "140px" : "60px" }}
          >
            <div className="rounded-r-xl bg-green12 w-[50px] h-full flex justify-center items-center text-white font-extrabold text-base">
              {activeLocation ? activeLocation.shortLabel : "ALL"}
            </div>
            {isDrawerOpen ? (
              <div className="grid grid-rows-2 grid-flow-col p-1 h-[90%] gap-1">
                {locationsData?.locations.map(
                  (location: { shortLabel: string; _id: string }) => (
                    <div
                      key={location._id}
                      className={`row-span-1 ${
                        location._id === activeLocation?._id
                          ? "bg-green12 text-white"
                          : "bg-white text-green12"
                      } rounded-lg w-fit h-fit text-[8px] font-medium px-1 py-1 cursor-pointer`}
                      onClick={() => {
                        if (activeLocationId !== location._id) {
                          setActiveLocation(location);
                        }
                      }}
                    >
                      {location.shortLabel}
                    </div>
                  )
                )}
                <div
                  key={"all"}
                  className={`row-span-1 ${
                    !activeLocation
                      ? "bg-green12 text-white"
                      : "bg-white text-green12"
                  } rounded-lg w-fit h-fit text-[8px] font-medium px-1 py-1 cursor-pointer`}
                  onClick={() => {
                    if (activeLocation) {
                      setActiveLocation(undefined);
                      setIsLocationFilter(true);
                      setIsDayOffFilter(true);
                      setTableParams({
                        isLocationFilter_: true,
                        locationId: null,
                      });
                    }
                  }}
                >
                  ALL
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              className="absolute right-0 cursor-pointer"
              onClick={() => {
                setIsDrawerOpen(!isDrawerOpen);
              }}
            >
              <CarotTwo
                type={isDrawerOpen ? "left" : "right"}
                pathFill={"#1D1B20"}
                size={{ height: "10px", width: "8px" }}
              />
            </div>
          </div>
          {!isDrawerOpen && (
            <>
              <div
                className={`${
                  !activeLocation && isLocationFilter
                    ? "bg-green12 text-white"
                    : "bg-white text-green12"
                } w-[35px] rounded-lg shadow-xl flex items-center justify-center cursor-pointer`}
                onClick={() => {
                  if (activeLocationId === "" && isDayOffFilter) {
                    setIsLocationFilter(!isLocationFilter);
                    setTableParams({ isLocationFilter_: !isLocationFilter });
                  } else {
                    setActiveLocation(undefined);
                    setIsLocationFilter(true);
                    setTableParams({
                      isLocationFilter_: true,
                      locationId: null,
                    });
                  }
                }}
              >
                <div>
                  <div className="text-center font-semibold text-sm">ALL</div>
                  <div className="text-center font-medium text-[8px]">
                    ZONES
                  </div>
                </div>
              </div>
              <div className="grid grid-rows-2 ml-3 grid-flow-col gap-1">
                {menuButtons.map((button) => (
                  <div
                    className={`${
                      button.isClicked
                        ? "bg-green14 justify-center items-center"
                        : "bg-white"
                    } rounded-md drop-shadow-lg h-[25px] ${
                      button.expandedElement && button.isClicked
                        ? "w-[80px] items-center px-1.5 relative col-span-3"
                        : "w-[25px] justify-center items-center col-span-1"
                    } cursor-pointer flex row-span-1 transition-[width] duration-300`}
                    onClick={button.handleClick}
                  >
                    {button.element}
                    {button.isClicked && Boolean(button.expandedElement) && (
                      <div className="flex justify-center flex-grow">
                        {button.expandedElement}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex mt-1">
        <div className="bg-gray8 rounded-md flex gap-1 p-1">
          {SENIORITIES.filter(
            (seniority) => seniority.id !== activeSeniorityId
          ).map((seniority) => (
            <div
              className={`bg-[${seniority.colorCode}] text-center rounded-md w-[26px] text-[10px] text-white font-semibold cursor-pointer`}
              key={seniority.id}
              onClick={() => {
                setActiveId(seniority.id);
                setTableParams({ seniority: seniority.id });
                setDoctorFilter({ tags: [], group: undefined, subGroups: [] });
              }}
            >
              {seniority.shortLabel}
            </div>
          ))}
          {activeSeniorityId && (
            <div
              className="bg-black text-center rounded-md w-[26px] text-[10px] text-white font-semibold cursor-pointer"
              onClick={() => {
                setActiveId(undefined);
                setTableParams({ seniority: null });
                setDoctorFilter({ tags: [], group: undefined, subGroups: [] });
              }}
            >
              ALL
            </div>
          )}
        </div>
        <div className="bg-white w-full rounded-l-full rounded-r-full px-2 relative flex h-[23px] items-center border-gray13 border-2">
          <input
            placeholder="Search"
            className="bg-white w-full outline-none h-full text-[9px] font-medium"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <div className="absolute right-1 top-[4px]">
            <SearchTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuOptions;
