import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

import { GROUP_COLORS, Key, TimeObject } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

import {
  useCreateShiftMutation,
  useDeleteShiftMutation,
  useUpdateShiftMutation,
} from "@/api/shiftsApi";
import { useAssignRandomnMutation } from "@/api/rosterApi";

import useHandleSuccessErrors from "@/hooks/handleSuccessErrors";
import useHandleOnScheduleChange from "@/hooks/estimation/handleOnScheduleAdd";

import { getDateRange } from "@/utils/date";
import { formatDateAPI, formatTime, getTimeObject } from "@/utils/formatDate";
import handleResponse from "@/utils/handleResponse";

import { ShiftType } from "@/interface/shift";

import Grid from "@/components/Icons/Grid";
import Delete from "@/components/Icons/Delete";
import { Minus } from "@/components/Icons/Minus";
import { Add } from "@/components/Icons/Add";
import Pen from "@/components/Icons/Pen";
import { CarotRight } from "../Icons/CarotRight";
import { CarotLeft } from "../Icons/CarotLeft";
import { Check } from "../Icons/Check";
import Plus from "../Icons/Plus";
import { useLocation } from "@/store/location.store";
import { useSeniority } from "@/store/seniority.state";
import { useTableData } from "./hooks/data";
import CrossTwo from "../Icons/CrossTwo";
import { toast } from "react-toastify";

type ShiftCardProps = {
  shiftData?: {
    _id: string;
    time: { to: string; from: string };
    type: ShiftType;
    slots: any[];
  };
  default_?: {
    time: {
      from: TimeObject;
      to: TimeObject;
    };
    type: ShiftType;
  };
  className?: string;
  isFetching: boolean;
  date: Date;
  groupKey: Key;
  onClose?: () => void;
};

const shiftsMap = {
  normal: { label: "Shift" },
  onCall: { label: "On Call" },
  event: { label: "Event" },
  standBy: { label: "Stand By" },
};

const getTimeString = (timeObject: {
  hour: number;
  minute: number;
  amOrPm: "am" | "pm";
}): string =>
  `${timeObject.hour.toString().padStart(2, "0")}:${timeObject.minute
    .toString()
    .padStart(2, "0")} ${timeObject.amOrPm.toUpperCase()}`;

// REFACTOR NEEDED

const FOCUS_COLORS = {
  morning: "focus:bg-brown2 focus:text-white",
  noon: "focus:bg-green4 focus:text-white",
  night: "focus:bg-blue1 focus:text-white",
  standBy: "focus:bg-red4 focus:text-white",
  dayOff: "",
};

const ShiftCard = ({
  shiftData,
  className,
  isFetching,
  date,
  groupKey,
  onClose,
  default_,
}: ShiftCardProps) => {
  const { editShiftId, setEditShiftId, month, year, type } =
    useTableViewContext();

  const { refetchGetShiftsByMonth } = useTableData({ month, year, type });

  const [updateShift, { isLoading: isUpdateLoading }] =
    useUpdateShiftMutation();

  const [deleteShift, { isLoading: isDeleteShiftLoading }] =
    useDeleteShiftMutation();

  const [createShift, { isLoading: isLoadingCreateShift }] =
    useCreateShiftMutation();

  const onScheduleChange = useHandleOnScheduleChange({
    ...getDateRange(date),
  });

  const [assignRandomn, { isLoading: isAssignRandomLoading }] =
    useAssignRandomnMutation();

  const handleAdd = async () => {
    if (shiftData && shiftData.slots.length < 8) {
      const response = await updateShift({
        shiftId: shiftData._id,
        totalDoctorsRequired: shiftData.slots.length + 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    }
  };

  const handleRemove = async () => {
    if (
      shiftData &&
      shiftData.slots.length >
        shiftData.slots.filter((slot) => slot.schedule).length
    ) {
      const response = await updateShift({
        shiftId: shiftData._id,
        totalDoctorsRequired: shiftData.slots.length - 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    }
  };

  const [shiftState, setShiftState] = useState<{
    time: {
      from: TimeObject;
      to: TimeObject;
    };
    type: "normal" | "standBy" | "onCall" | "event";
  }>({
    time: {
      from: shiftData
        ? getTimeObject(shiftData.time.from)
        : default_!.time.from,
      to: shiftData ? getTimeObject(shiftData.time.to) : default_!.time.to,
    },
    type: shiftData?.type ?? default_!.type,
  });

  const [tabType, setTabType] = useState<"time" | "type">("time");

  const [mode, setMode] = useState<"edit" | "delete" | "none">("none");

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const isLoading =
    isUpdateLoading ||
    isDeleteShiftLoading ||
    isAssignRandomLoading ||
    isLoadingCreateShift ||
    isFetching;

  return (
    <div
      className={`col-span-1 relative transition-all ${className ?? ""} h-fit`}
    >
      <div
        className={`rounded-xl py-1.5 bg-white ${
          !shiftData || (shiftData && editShiftId === shiftData._id)
            ? `${GROUP_COLORS[groupKey].borderColor2} border`
            : ``
        } ${isLoading ? "animate-pulseFast" : ""} h-full`}
      >
        <div className="relative mx-1.5 mb-1">
          {shiftData && editShiftId === shiftData._id && mode === "none" ? (
            <>
              <button
                disabled={isLoading}
                className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-0 p-0.5`}
                onClick={async () => {
                  const response = await assignRandomn({
                    shiftId: shiftData._id,
                  });
                  handleResponse(
                    response,
                    "Schedules auto assigned.",
                    async () => {
                      await refetchGetShiftsByMonth();
                      await onScheduleChange();
                    }
                  );
                }}
              >
                <Grid
                  className={isAssignRandomLoading ? "animate-spin" : ""}
                  fill={"black"}
                />
              </button>
            </>
          ) : null}
          <button
            onClick={() => {
              if (editShiftId && shiftData && editShiftId === shiftData._id) {
                setEditShiftId(undefined);
                setMode("none");
              } else if (shiftData) {
                setEditShiftId(shiftData._id);
              }
            }}
            disabled={isAssignRandomLoading}
            className={`header w-full ${
              shiftData ? "px-4" : "px-2"
            } py-1 flex justify-center items-center text-[10px] rounded-md transition-all text-black ${
              !shiftData || (shiftData && editShiftId === shiftData._id)
                ? `${GROUP_COLORS[groupKey].bgColor10} font-bold`
                : `${GROUP_COLORS[groupKey].bgColor9} font-semibold`
            }`}
          >
            {shiftData
              ? `${formatTime(shiftData.time.from ?? "")} - ${formatTime(
                  shiftData.time.to ?? ""
                )}`
              : `${getTimeString(shiftState.time.from)} - ${getTimeString(
                  shiftState.time.to
                )}`}
          </button>
        </div>
        {shiftData && mode !== "edit" ? (
          <div className="max-w-md mx-auto">
            <AnimatePresence>
              {shiftData.slots.length === 0 ? (
                <motion.div
                  key="noDataTransition"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
                >
                  <div className="font-medium text-sm p-1 text-black2 text-center">
                    No Data
                  </div>
                </motion.div>
              ) : null}
              {shiftData.slots.map((slot, index) => (
                <div
                  key={slot._id}
                  className={`h-7 ${
                    index != shiftData.slots.length - 1
                      ? "border-b-[1px] border-green12"
                      : ""
                  }`}
                >
                  <div className="h-full w-full flex justify-center items-center text-xs font-semibold">
                    {slot.schedule
                      ? slot.schedule.doctor.user.nickName ??
                        slot.schedule.doctor.user.name
                      : "+"}
                  </div>
                </div>
              ))}
            </AnimatePresence>
          </div>
        ) : (
          <div className="max-w-md mx-auto">
            {tabType === "time" && (
              <div className="flex mx-2 h-[45px]">
                <div className="mr-0.5">
                  <div className="flex items-center mb-1">
                    <div className="text-[10px] w-6 mr-1">Start</div>
                    <div
                      className={`flex ${GROUP_COLORS[groupKey].bgColor9} p-0.5 rounded-md items-center`}
                    >
                      <input
                        className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                        value={shiftState.time.from.hour
                          .toString()
                          .padStart(2, "0")}
                        onChange={(event) => {
                          const value = event.target.value.replace(/^0+/, "");
                          if (
                            value.length <= 2 &&
                            !Number.isNaN(Number(value)) &&
                            Number(value) >= 0 &&
                            Number(value) <= 12
                          ) {
                            setShiftState({
                              ...shiftState,
                              time: {
                                ...shiftState.time,
                                from: {
                                  ...shiftState.time.from,
                                  hour: Number(value),
                                },
                              },
                            });
                          }
                        }}
                      />
                      <div className="text-[9px] mx-0.5">:</div>
                      <input
                        className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                        value={shiftState.time.from.minute
                          .toString()
                          .padStart(2, "0")}
                        onChange={(event) => {
                          const value = event.target.value.replace(/^0+/, "");
                          if (
                            value.length <= 2 &&
                            !Number.isNaN(Number(value)) &&
                            Number(value) >= 0 &&
                            Number(value) <= 60
                          ) {
                            setShiftState({
                              ...shiftState,
                              time: {
                                ...shiftState.time,
                                from: {
                                  ...shiftState.time.from,
                                  minute: Number(value),
                                },
                              },
                            });
                          }
                        }}
                      />
                      <div
                        className="text-[9px] mx-0.5 cursor-pointer uppercase text-nowrap"
                        onClick={() => {
                          setShiftState({
                            ...shiftState,
                            time: {
                              ...shiftState.time,
                              from: {
                                ...shiftState.time.from,
                                amOrPm:
                                  shiftState.time.from.amOrPm === "am"
                                    ? "pm"
                                    : "am",
                              },
                            },
                          });
                        }}
                      >
                        {shiftState.time.from.amOrPm}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <div className="text-[10px] w-6 mr-1 flex flex-row-reverse">
                      End
                    </div>
                    <div
                      className={`flex ${GROUP_COLORS[groupKey].bgColor9} p-0.5 rounded-md items-center`}
                    >
                      <input
                        className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                        value={shiftState.time.to.hour
                          .toString()
                          .padStart(2, "0")}
                        onChange={(event) => {
                          const value = event.target.value.replace(/^0+/, "");
                          if (
                            value.length <= 2 &&
                            !Number.isNaN(Number(value)) &&
                            Number(value) >= 0 &&
                            Number(value) <= 12
                          ) {
                            setShiftState({
                              ...shiftState,
                              time: {
                                ...shiftState.time,
                                to: {
                                  ...shiftState.time.to,
                                  hour: Number(value),
                                },
                              },
                            });
                          }
                        }}
                      />
                      <div className="text-[9px] mx-0.5">:</div>
                      <input
                        className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                        value={shiftState.time.to.minute
                          .toString()
                          .padStart(2, "0")}
                        onChange={(event) => {
                          const value = event.target.value.replace(/^0+/, "");
                          if (
                            value.length <= 2 &&
                            !Number.isNaN(Number(value)) &&
                            Number(value) >= 0 &&
                            Number(value) <= 60
                          ) {
                            setShiftState({
                              ...shiftState,
                              time: {
                                ...shiftState.time,
                                to: {
                                  ...shiftState.time.to,
                                  minute: Number(value),
                                },
                              },
                            });
                          }
                        }}
                      />
                      <div
                        className="text-[9px] mx-0.5 cursor-pointer uppercase"
                        onClick={() => {
                          setShiftState({
                            ...shiftState,
                            time: {
                              ...shiftState.time,
                              to: {
                                ...shiftState.time.to,
                                amOrPm:
                                  shiftState.time.to.amOrPm === "am"
                                    ? "pm"
                                    : "am",
                              },
                            },
                          });
                        }}
                      >
                        {shiftState.time.to.amOrPm}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div
                    className={`text-[8px] ${GROUP_COLORS[groupKey].bgColor10} text-white py-0.5 text-center text-nowrap rounded-md mb-1 w-[35px]`}
                  >
                    {shiftsMap[shiftState.type].label}
                  </div>
                  <div
                    className="flex flex-grow justify-center items-center cursor-pointer"
                    onClick={() => {
                      setTabType("type");
                    }}
                  >
                    <CarotRight
                      pathFill="black"
                      size={{ width: "12px", height: "16px" }}
                    />
                  </div>
                </div>
              </div>
            )}
            {tabType === "type" && (
              <div className="mx-1.5 h-[45px]">
                <div className="flex gap-1 justify-between mb-1.5">
                  <div
                    className={`text-[8px] ${
                      shiftState.type === "normal"
                        ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                        : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
                    } py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
                    onClick={() => {
                      if (shiftState.type !== "normal") {
                        setShiftState({ ...shiftState, type: "normal" });
                      }
                    }}
                  >
                    Shift
                  </div>
                  <div
                    className={`text-[8px] ${
                      shiftState.type === "onCall"
                        ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                        : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
                    } py-0.5 text-center rounded-md w-[35px] whitespace-nowrap cursor-pointer`}
                    onClick={() => {
                      if (shiftState.type !== "onCall") {
                        setShiftState({ ...shiftState, type: "onCall" });
                      }
                    }}
                  >
                    On Call
                  </div>
                  <div
                    className={`text-[8px] ${
                      shiftState.type === "event"
                        ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                        : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
                    }  py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
                    onClick={() => {
                      if (shiftState.type !== "event") {
                        setShiftState({ ...shiftState, type: "event" });
                      }
                    }}
                  >
                    Event
                  </div>
                </div>
                <div className="flex gap-1 justify-between mb-1.5">
                  <div
                    className="w-[35px] flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      setTabType("time");
                    }}
                  >
                    <CarotLeft
                      pathFill="black"
                      size={{ width: "12px", height: "16px" }}
                    />
                  </div>
                  <div
                    className={`text-[8px] ${
                      shiftState.type === "standBy"
                        ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                        : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
                    }  py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
                    onClick={() => {
                      if (shiftState.type !== "standBy") {
                        setShiftState({ ...shiftState, type: "standBy" });
                      }
                    }}
                  >
                    StandBy
                  </div>
                  <div className="w-[35px]"></div>
                </div>
              </div>
            )}
          </div>
        )}
        {(!shiftData || (shiftData && editShiftId === shiftData._id)) && (
          <div
            className={`${GROUP_COLORS[groupKey].bgColor10} mx-1 px-1 py-0.5 rounded-md transition-all flex justify-between items-center`}
          >
            {(mode === "none" || mode === "edit") && shiftData && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setMode("delete");
                }}
              >
                <Delete stroke="stroke-black" />
              </div>
            )}
            {(mode === "none" || mode === "delete") && shiftData && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setMode("edit");
                }}
              >
                <Pen pathFill="black" />
              </div>
            )}
            {!shiftData && <div className="flex flex-grow"></div>}
            {mode === "none" && shiftData ? (
              <div
                className={`flex items-center text-xs justify-between rounded-md ${GROUP_COLORS[groupKey].bgColor6} w-12`}
              >
                <button
                  className="p-0.5 cursor-pointer rounded-sm"
                  onClick={() => handleRemove()}
                >
                  <Minus className="h-4 w-4" stroke={`stroke-black`} />
                </button>
                <button
                  className="p-0.5 cursor-pointer rounded-sm"
                  onClick={() => handleAdd()}
                >
                  <Add className="h-4 w-4 " stroke={`stroke-black`} />
                </button>
              </div>
            ) : (
              <div
                className={`flex items-center text-xs justify-between rounded-md ${GROUP_COLORS[groupKey].bgColor6} p-0.5`}
              >
                {
                  <div
                    className={`flex ${GROUP_COLORS[groupKey].bgColor11} text-white text-[10px] rounded-md py-0.5 px-1 items-center`}
                  >
                    {shiftData && mode === "delete" && (
                      <>
                        <Delete stroke="stroke-white" />
                        delete
                      </>
                    )}
                    {shiftData && mode === "edit" && (
                      <>
                        <Pen pathFill="white" />
                        edit
                      </>
                    )}
                    {!shiftData && (
                      <>
                        <Plus
                          color={"white"}
                          size={{ height: "15px", width: "15px" }}
                        />{" "}
                        add
                      </>
                    )}
                  </div>
                }
                <button
                  className="text-white p-0.5 cursor-pointer rounded-sm mx-1"
                  onClick={() => {
                    if (shiftData) {
                      setMode("none");
                    } else {
                      onClose?.();
                    }
                  }}
                >
                  <CrossTwo pathFill="black" />
                </button>
                <div className="h-[20px] w-[1px] bg-white"></div>
                <button
                  className="text-white p-0.5 cursor-pointer rounded-sm mx-0.5"
                  onClick={async () => {
                    if (mode === "delete" && shiftData) {
                      const response = await deleteShift({
                        shiftId: shiftData?._id,
                      });
                      handleResponse(response, "Shift Deleted.", async () => {
                        await refetchGetShiftsByMonth();
                      });
                    } else if (shiftData) {
                      const response = await updateShift({
                        shiftId: shiftData?._id,
                        time: {
                          from: getTimeString(shiftState.time.from),
                          to: getTimeString(shiftState.time.to),
                        },
                      });
                      handleResponse(response, "Shift Updated.", async () => {
                        await refetchGetShiftsByMonth();
                      });
                    } else {
                      if (!activeLocationId || !activeSeniorityId) {
                        toast.warning(
                          "Shift cannot be created until seniority or location selected."
                        );
                      } else {
                        const response = await createShift({
                          time: {
                            from: getTimeString(shiftState.time.from),
                            to: getTimeString(shiftState.time.to),
                          },
                          type: shiftState.type,
                          totalDoctorsRequired: 1,
                          locationId: activeLocationId,
                          seniority: activeSeniorityId,
                          date: formatDateAPI(date),
                        });
                        handleResponse(response, "Shift Created.", async () => {
                          await refetchGetShiftsByMonth();
                          onClose?.();
                        });
                      }
                    }
                  }}
                >
                  <Check
                    stroke="black"
                    size={{ height: "15px", width: "15px" }}
                  />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShiftCard;
