const GridTwo = ({
  pathFill,
  width = "10",
  height = "10",
}: {
  pathFill: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5 3V0H9V3H5ZM0 5V0H4V5H0ZM5 9V4H9V9H5ZM0 9V6H4V9H0ZM1 4H3V1H1V4ZM6 8H8V5H6V8ZM6 2H8V1H6V2ZM1 8H3V7H1V8Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default GridTwo;
