import { HiPlus } from "react-icons/hi";
import { useModal } from "../../../hooks/useModal";
import CreateSubGroupModal from "../../Modals/CreateSubGroup";
import SubGroupPill from "../../Pills/SubGroupPill";
import HorizontalScrollContainer from "../../HorizontalScrollContainer";

const SubGroupBar = ({
  activeGroupData,
  unassignedDoctorsInGroup,
  triggerReload,
  activeSubGroupId,
  isUnassignedActive,
  setUniqueDoctorTags,
  setDoctorsList,
  setTriggerReload,
  setActiveTags,
  setIsUnassignedActive,
  setActiveSubGroupId,
  setActiveSubGroupData,
  setSelectedUser
}: {
  activeGroupData: any;
  unassignedDoctorsInGroup: any;
  triggerReload: boolean;
  activeSubGroupId: string;
  isUnassignedActive: boolean;
  setUniqueDoctorTags: (value: any[]) => void;
  setDoctorsList: (value: any[]) => void;
  setTriggerReload: (value: boolean) => void;
  setActiveTags: (value: any[]) => void;
  setIsUnassignedActive: (value: boolean) => void;
  setActiveSubGroupId: (value: string) => void;
  setActiveSubGroupData: (value: any) => void;
  setSelectedUser: React.Dispatch<any>;
}) => {
  const { openModal } = useModal();

  return (
    <div className="flex items-center mb-6">
      <div
        className="w-fit cursor-pointer"
        onClick={() => {
          openModal(
            <CreateSubGroupModal
              group={activeGroupData}
              unassignedDoctorsInGroup={unassignedDoctorsInGroup}
              onSuccess={() => {
                setTriggerReload(!triggerReload);
              }}
            />,
          );
        }}
      >
        {activeGroupData?.subGroups?.length == 0 ? (
          <SubGroupPill
            name="Add Subgroup"
            children={
              <div className="bg-white cursor-pointer drop-shadow-md flex items-center justify-center !w-8 !h-8 !rounded-full">
                <HiPlus className="text-orange2" />
              </div>
            }
            className="h-12 w-64 3xl:scale-100 scale-75 !rounded-2xl flex justify-between items-center !bg-[#838383] !bg-opacity-20"
          />
        ) : (
          <div className="bg-white 3xl:scale-100 scale-75 cursor-pointer drop-shadow-md flex items-center justify-center !w-8 !h-8 !rounded-full mr-2">
            <HiPlus className="text-orange2" />
          </div>
        )}
      </div>
      <HorizontalScrollContainer className="px-1">
        {activeGroupData?.subGroups?.map((sub: any, index: number) => {
          const isActive = sub._id == activeSubGroupId;
          return (
            <div
              className=""
              onClick={() => {
                setIsUnassignedActive(false);
                setActiveTags([]);
                setActiveSubGroupId(sub._id);
                setActiveSubGroupData(sub);
                setDoctorsList([...sub.doctors]);
                setUniqueDoctorTags(sub?.doctors);
                setSelectedUser(undefined)
              }}
            >
              <SubGroupPill
                key={index}
                isActive={isActive}
                children={
                  <div className="3xl:w-8 3xl:h-8 w-5 h-5 rounded-full text-[12px] flex justify-center items-center">
                    {sub.doctors.length}
                  </div>
                }
                name={sub.title}
                className={`
                  3xl:h-[50px] h-9
                   min-w-56 cursor-pointer line-clamp-1 3xl:text-sm text-xs !px-6 font-bold 3xl:!rounded-2xl rounded-xl flex justify-center gap-3 items-center ${
                     sub.title === activeGroupData.title
                   }`}
              />
            </div>
          );
        }) ?? []}
      </HorizontalScrollContainer>
      <div
        onClick={() => {
          setActiveSubGroupId("");
          setActiveTags([]);
          setIsUnassignedActive(true);
          setDoctorsList([...unassignedDoctorsInGroup]);
          setUniqueDoctorTags(unassignedDoctorsInGroup);
        }}
        className="ml-3"
      >
        <SubGroupPill
          name="Unassigned"
          children={
            <div
              className={`3xl:w-8 3xl:h-8 w-6 h-6 rounded-full flex justify-center items-center gap-4 ${
                isUnassignedActive
                  ? "!bg-purple2 !text-purple1"
                  : "!bg-purple1 !text-purple2"
              }`}
            >
              {unassignedDoctorsInGroup?.length ?? 0}
            </div>
          }
          className={`3xl:scale-100 scale-75 h-[50px] w-fit cursor-pointer text-sm  !px-6 font-bold !rounded-2xl flex justify-between items-center gap-x-3 ${
            isUnassignedActive
              ? "!bg-purple1 !text-purple2"
              : "!bg-purple2 !text-purple1"
          }`}
        />
      </div>
    </div>
  );
};

export default SubGroupBar