import { HiPlus } from "react-icons/hi";
import { useModal } from "../../../hooks/useModal";
import Button from "../../Button";
import CreateGroupModal from "../../Modals/CreateGroup";
import GroupCard from "./GroupCard";

const GroupsSideBar = ({
  groups,
  activeGroupId,
  unassignedDoctors,
  triggerReload,
  refetchGroups,
  setIsUnassignedActive,
  setActiveGroupId,
  setActiveSubGroupId,
  setActiveGroupData,
  setDoctorsList,
  setActiveTags,
  setUniqueDoctorTags,
  setTriggerReload,
  setSelectedUser,
}: {
  groups: any[];
  activeGroupId: string;
  unassignedDoctors: { doctors: any[] };
  triggerReload: boolean;
  refetchGroups: () => void;
  setIsUnassignedActive: (value: boolean) => void;
  setActiveGroupId: (value: string) => void;
  setActiveSubGroupId: (value: string) => void;
  setActiveGroupData: (value: any) => void;
  setDoctorsList: (value: any[]) => void;
  setActiveTags: (value: any[]) => void;
  setUniqueDoctorTags: (value: any[]) => void;
  setTriggerReload: (value: boolean) => void;
  setSelectedUser: React.Dispatch<any>;
}) => {
  const { openModal } = useModal();

  return (
    <div className="col-span-2  gap-y-2 flex flex-col overflow-y-auto border-t-0 border-primary border-r-2 p-2">
      {groups?.map?.((group: any) => (
        <GroupCard
          onClick={() => {
            setIsUnassignedActive(false);
            setActiveGroupId(group.id);
            setActiveSubGroupId("");
            setActiveGroupData(group);
            setDoctorsList([...(group?.doctors ?? [])]);
            setActiveTags([]);
            setUniqueDoctorTags(group?.doctors);
            setSelectedUser(undefined);
          }}
          name={group.title}
          isActive={activeGroupId === group.id}
        />
      ))}
      <div className="bg-[#83838333] rounded-lg flex flex-col items-center justify-center p-6">
        <Button
          onClick={() => {
            openModal(
              <CreateGroupModal
                unassignedDoctors={unassignedDoctors.doctors}
                onSubmit={() => {
                  refetchGroups();
                  setTriggerReload(!triggerReload);
                }}
              />
            );
          }}
          className="bg-white drop-shadow-md flex items-center justify-center !w-10 !h-10 !rounded-full"
          icon={{
            position: "left",
            content: <HiPlus className="text-secondary" />,
          }}
        />
      </div>
    </div>
  );
};

export default GroupsSideBar;
