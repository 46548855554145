import { useEffect, useRef, useState } from "react";
import Slider from "rc-slider";
import { useDroppable } from "@dnd-kit/core";
import { motion } from "framer-motion";

import SlotPill from "./SlotPill.tsx";
import { InputSlotMode } from "./InputSlot.tsx";

import HorizontalScrollContainer from "../HorizontalScrollContainer.tsx";
import Rivet from "../Icons/Rivet.tsx";
import RoundedCancelOutline from "../Icons/RoundedCancelOutline.tsx";
import DoctorCard from "../Cards/SmallDoctorCard.tsx";
import RoundedTickOutline from "../Icons/RoundedTickOutline.tsx";
import Delete from "../Icons/Delete.tsx";

import { useSeniority } from "@/store/seniority.state.tsx";
import addOrRemove from "@/utils/addOrRemoveArray.ts";
import {
  useCreateSlotFilterMutation,
  useDeleteSlotFilterMutation,
  useUpdateSlotFilterMutation,
} from "@/api/shiftsApi.ts";
import { SENIORITIES } from "../../constants.ts";
import { useGetGroupsQuery } from "@/api/groupApi.ts.ts";
import { useGetTagsQuery } from "@/api/doctorApi.ts";
import { useEstDndContext } from "@/hooks/context/useEstDndContext.tsx";
import { Filter, FilterState } from "@/interface/shift.ts";
import { Doctor } from "@/interface/doctor.ts";
import handleResponse from "@/utils/handleResponse.ts";
import useKey from "@/hooks/useKey";

type Mode = "group" | "tag" | "users" | "experience" | "efficiency";

const ITEMS: { label: string; key: Mode; colorCode: string }[] = [
  { label: "Group", key: "group", colorCode: "#8E74A7" },
  { label: "Tag", key: "tag", colorCode: "#9B5140" },
  { label: "Experience", key: "experience", colorCode: "#3E7C76" },
  { label: "Efficiency", key: "efficiency", colorCode: "#529A4D" },
  { label: "Users", key: "users", colorCode: "#496F92" },
];

const getSeniorityColor = (seniority: number): string => {
  return (
    SENIORITIES.find((seniority_) => seniority_.id === seniority)?.colorCode ??
    ""
  );
};

const checkIfFilterEnabled = (
  mode: Mode,
  filterState: FilterState,
  doctors?: Doctor[]
): boolean => {
  switch (mode) {
    case "group":
      return Boolean(filterState.seniority);
    case "tag":
      return filterState.tags.length > 0;
    case "efficiency":
      return filterState.efficiency > 0;
    case "experience":
      return filterState.experience > 0;
    case "users":
      return (doctors?.length ?? 0) > 0;
  }
};

const getCurrentGroup = (filterState: FilterState, doctors?: Doctor[]) => {
  return checkIfFilterEnabled("users", filterState, doctors)
    ? "users"
    : ITEMS.find((item) => {
        return checkIfFilterEnabled(item.key, filterState, doctors);
      })?.key ?? "group";
};

const extractFilterValues = (
  filter: Required<Omit<Filter, "_id" | "seniority">> & { seniority?: number }
): Omit<Filter, "_id" | "doctors"> & { doctors?: string[] } => {
  const type = filter.doctors.length > 0 ? "doctor" : "rest";

  return {
    ...(filter.seniority && type === "rest"
      ? { seniority: filter.seniority }
      : {}),
    ...(filter.groups.length > 0 && type === "rest"
      ? { groups: filter.groups }
      : {}),
    ...(filter.subGroups.length > 0 && type === "rest"
      ? { subGroups: filter.subGroups }
      : {}),
    ...(filter.tags.length > 0 && type === "rest" ? { tags: filter.tags } : {}),
    ...(filter.experience > 0 && type === "rest"
      ? { experience: filter.experience }
      : {}),
    ...(filter.efficiency > 0 && type === "rest"
      ? { efficiency: filter.efficiency }
      : {}),
    ...(type === "doctor"
      ? { doctors: filter.doctors.map((doctor) => doctor._id) }
      : {}),
  };
};

const FilterSlot = ({
  shiftId,
  slotIndex,
  filter,
  schedule,
  setMode: setModeSlot,
  onScheduleAdd,
}: {
  shiftId: string;
  slotIndex: number;
  filter: Filter | undefined;
  schedule: any | undefined;
  setMode: (mode: InputSlotMode) => void;
  onScheduleAdd: ({
    shouldRefetchCalendar,
  }: {
    shouldRefetchCalendar?: boolean;
  }) => void;
}) => {
  const { activeFilterDoctors, setActiveFilterDoctors } = useEstDndContext();
  const { activeId: activeSeniorityId } = useSeniority();
  const [filterState, setFilterState] = useState<FilterState>({
    seniority: filter ? filter.seniority : activeSeniorityId,
    groups: filter?.groups ? [...filter.groups] : [],
    subGroups: filter?.subGroups ? [...filter.subGroups] : [],
    tags: filter?.tags ? [...filter.tags] : [],
    experience: filter?.experience ?? 0,
    efficiency: filter?.efficiency ?? 0,
  });

  const doctors = activeFilterDoctors[`${shiftId}-${slotIndex}`];

  const [mode, setMode] = useState<Mode>(() => {
    return getCurrentGroup(filterState, doctors);
  });

  const [createSlotFilter, { isLoading: isCreateSlotFilterLoading }] =
    useCreateSlotFilterMutation();

  const [updateSlotFilter, { isLoading: isUpdateSlotFilterLoading }] =
    useUpdateSlotFilterMutation();

  const [deleteSlotFilter, { isLoading: isDeleteSlotFilterLoading }] =
    useDeleteSlotFilterMutation();

  const divRef = useRef<null | HTMLDivElement>(null);

  const { isKeyPressed, setKeyPressed } = useKey(divRef, [
    "Tab",
    "Enter",
    "Escape",
  ]);

  const handleSubmit = async () => {
    if (filter) {
      const response = await updateSlotFilter({
        slotFilterId: filter._id,
        scheduleId: schedule?._id,
        payload: extractFilterValues({
          ...filterState,
          doctors: activeFilterDoctors[`${shiftId}-${slotIndex}`],
        }),
      });
      handleResponse(response, "Slot filter updated.", () => {
        onScheduleAdd({});
        setModeSlot("close");
      });
    } else {
      const response = await createSlotFilter({
        slotIndex: slotIndex,
        shiftId,
        payload: extractFilterValues({
          ...filterState,
          doctors: activeFilterDoctors[`${shiftId}-${slotIndex}`],
        }),
      });
      handleResponse(response, "Slot filter created.", () => {
        onScheduleAdd({});
        setModeSlot("close");
      });
    }
  };

  useEffect(() => {
    if (isKeyPressed["Enter"]) {
      handleSubmit().then(() => {
        setModeSlot("doctor");
      });
      setKeyPressed({ ...isKeyPressed, Escape: false });
      return;
    }
    if (isKeyPressed["Escape"]) {
      setModeSlot("doctor");
    }
  }, [isKeyPressed]);

  useEffect(() => {
    setActiveFilterDoctors({
      ...activeFilterDoctors,
      [`${shiftId}-${slotIndex}`]: filter?.doctors ?? [],
    });
  }, []);

  const { setNodeRef, isOver } = useDroppable({
    id: `doctor-${shiftId}-${slotIndex}`,
    data: {
      type: "doctorCard",
      key: `${shiftId}-${slotIndex}`,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      divRef.current?.focus();
    }, 0);
  }, []);

  const {
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    data: groups,
  } = useGetGroupsQuery({ seniority: 4, includeDoctors: false });

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const subGroups: any[] | undefined = groups
    ?.filter((group: { _id: string }) => filterState.groups.includes(group._id))
    .map((group: { subGroups: any[] }) => group.subGroups)
    .flat();

  const tagSplit = Math.floor(tags.length / 3);

  return (
    <motion.div
      key={`${slotIndex}-doctor-input`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={`bg-lightGreen3 ${
        isCreateSlotFilterLoading ||
        isUpdateSlotFilterLoading ||
        isDeleteSlotFilterLoading
          ? "animate-pulseFast"
          : ""
      } rounded-lg w-full p-3 relative outline-none`}
      ref={divRef}
      tabIndex={-1}
    >
      <div>
        <div className="flex justify-between">
          <div className={`flex`}>
            <div
              className={`border-y-2 border-secondary rounded-l w-8 h-[34px] flex items-center`}
            >
              <div
                className={`flex items-center cursor-pointer justify-center px-1.5 h-[110%] w-full 
               bg-secondary rounded`}
              >
                <Rivet fillColor={"white"} />
              </div>
            </div>
            <div
              className={`flex border-y-2 border-r-2 border-secondary p-2 rounded-r gap-2 bg-white`}
            >
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => {
                  setActiveFilterDoctors({
                    ...activeFilterDoctors,
                    [`${shiftId}-${slotIndex}`]: filter?.doctors ?? [],
                  });
                  setFilterState({
                    seniority: filter ? filter.seniority : activeSeniorityId,
                    groups: filter?.groups ?? [],
                    subGroups: filter?.subGroups ?? [],
                    tags: filter?.tags ?? [],
                    experience: filter?.experience ?? 0,
                    efficiency: filter?.efficiency ?? 0,
                  });
                  setModeSlot("doctor");
                }}
              >
                <RoundedCancelOutline />
              </div>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={async () => {
                  if (filter) {
                    const response = await updateSlotFilter({
                      slotFilterId: filter._id,
                      scheduleId: schedule?._id,
                      payload: extractFilterValues({
                        ...filterState,
                        doctors: activeFilterDoctors[`${shiftId}-${slotIndex}`],
                      }),
                    });
                    handleResponse(response, "Slot filter updated.", () => {
                      onScheduleAdd({});
                      setModeSlot("close");
                    });
                  } else {
                    const response = await createSlotFilter({
                      slotIndex: slotIndex,
                      shiftId,
                      payload: extractFilterValues({
                        ...filterState,
                        doctors: activeFilterDoctors[`${shiftId}-${slotIndex}`],
                      }),
                    });
                    handleResponse(response, "Slot filter created.", () => {
                      onScheduleAdd({});
                      setModeSlot("close");
                    });
                  }
                }}
              >
                <RoundedTickOutline />
              </div>
            </div>
          </div>
          {filter && (
            <div
              className="flex items-center cursor-pointer"
              onClick={async () => {
                const response = await deleteSlotFilter({
                  slotIndex: slotIndex,
                  shiftId,
                  slotFilterId: filter._id,
                });
                handleResponse(response, "Slot filter deleted.", () => {
                  onScheduleAdd({});
                  setActiveFilterDoctors({
                    ...activeFilterDoctors,
                    [`${shiftId}-${slotIndex}`]: [],
                  });
                  setModeSlot("close");
                });
              }}
            >
              <Delete stroke="stroke-pink1" />
            </div>
          )}
        </div>
        <div>
          <HorizontalScrollContainer className="mt-2 mb-1 mx-1 !gap-x-1">
            {ITEMS.map(
              (item: { label: string; colorCode: string; key: Mode }) => {
                const isFilterEnabled = checkIfFilterEnabled(
                  item.key,
                  filterState,
                  doctors
                );
                const isFilterEnabledUser = checkIfFilterEnabled(
                  "users",
                  filterState,
                  doctors
                );

                const isRestFilterEnabled =
                  isFilterEnabled && !isFilterEnabledUser;

                const type = (() => {
                  if (mode !== item.key && isRestFilterEnabled) {
                    return "outline";
                  }
                  return "full";
                })();

                return (
                  <SlotPill
                    label={item}
                    type={type}
                    isActive={mode === item.key || isRestFilterEnabled}
                    onClick={() => {
                      if (!isFilterEnabledUser && item.key !== "users") {
                        setMode(item.key);
                      } else if (item.key === "users") {
                        setMode(item.key);
                      }
                    }}
                  />
                );
              }
            )}
          </HorizontalScrollContainer>
          {mode === "group" && (
            <>
              <HorizontalScrollContainer className={`my-1 mx-1 !gap-x-1`}>
                {SENIORITIES.map((seniority) => (
                  <SlotPill
                    label={{
                      label: seniority.label,
                      colorCode: seniority.colorCode,
                      key: seniority.id.toString(),
                    }}
                    isActive={filterState.seniority === seniority.id}
                    onClick={() => {
                      setFilterState({
                        ...filterState,
                        seniority:
                          filterState.seniority === seniority.id
                            ? undefined
                            : seniority.id,
                        groups: [],
                        subGroups: [],
                      });
                    }}
                  />
                ))}
              </HorizontalScrollContainer>
              <HorizontalScrollContainer
                className={`my-1 mx-1 !gap-x-1 min-h-[26px] ${
                  isGroupsLoading || isGroupsFetching
                    ? "animate-pulseFast rounded-lg bg-lightGreen2"
                    : ""
                }`}
              >
                {groups
                  ?.filter(
                    (group: { seniority: { id: number } }) =>
                      filterState.seniority === group.seniority.id
                  )
                  .map(
                    (group: {
                      title: string;
                      _id: string;
                      seniority: { id: number };
                    }) => (
                      <SlotPill
                        label={{
                          label: group.title,
                          colorCode: getSeniorityColor(group.seniority.id),
                          key: group._id,
                        }}
                        isActive={filterState.groups.includes(group._id)}
                        onClick={() => {
                          setFilterState({
                            ...filterState,
                            groups: addOrRemove(filterState.groups, group._id),
                          });
                        }}
                      />
                    )
                  ) ?? []}
              </HorizontalScrollContainer>
              <HorizontalScrollContainer
                className={`my-1 mx-1 !gap-x-1 min-h-[26px] ${
                  isGroupsLoading || isGroupsFetching
                    ? "animate-pulseFast rounded-lg bg-lightGreen2"
                    : ""
                }`}
              >
                {[
                  subGroups?.map((subGroup) => (
                    <SlotPill
                      label={{
                        label: subGroup.title,
                        colorCode: getSeniorityColor(
                          groups.find(
                            (group: { _id: string }) =>
                              group._id === subGroup.groupId
                          ).seniority.id
                        ),
                        key: subGroup._id,
                      }}
                      type="outline"
                      isActive={filterState.subGroups.includes(subGroup._id)}
                      onClick={() => {
                        setFilterState({
                          ...filterState,
                          subGroups: addOrRemove(
                            filterState.subGroups,
                            subGroup._id
                          ),
                        });
                      }}
                    />
                  )) ?? [],
                ]}
              </HorizontalScrollContainer>
            </>
          )}
          {mode === "users" && (
            <>
              <HorizontalScrollContainer className={`py-1 px-1 !gap-x-1`}>
                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div
                    ref={setNodeRef}
                    className={`row-span-1 w-20 ${
                      isOver ? "border-dashed border-2 rounded-lg" : ""
                    } `}
                  >
                    <DoctorCard name={"+"} />
                  </div>
                  {doctors?.map((doctor) => (
                    <div
                      className="row-span-1 w-20"
                      onClick={() => {
                        setActiveFilterDoctors({
                          [`${shiftId}-${slotIndex}`]: [
                            ...activeFilterDoctors[
                              `${shiftId}-${slotIndex}`
                            ].filter((d) => d._id !== doctor._id),
                          ],
                        });
                      }}
                    >
                      <DoctorCard
                        name={doctor.user.name}
                        nickName={doctor.user.nickName}
                        seniority={doctor.seniority?.id ?? doctor.seniority}
                      />
                    </div>
                  )) ?? []}
                </div>
              </HorizontalScrollContainer>
            </>
          )}
          {mode === "tag" && (
            <>
              {Array(3)
                .fill(undefined)
                .map((_, index) => {
                  const startIndex = tagSplit * index;
                  const endIndex =
                    index === 2 ? tags.length + 1 : startIndex + tagSplit;
                  return (
                    <HorizontalScrollContainer
                      className={`my-1 mx-1 !gap-x-1 ${
                        isTagsLoading
                          ? "animate-pulseFast rounded-lg bg-lightGreen2"
                          : ""
                      }`}
                    >
                      {endIndex > tags.length + 1
                        ? []
                        : tags
                            .slice(startIndex, endIndex)
                            .map((tag: { value: string; _id: string }) => (
                              <SlotPill
                                label={{
                                  label: tag.value,
                                  colorCode: "#9B5140",
                                  key: tag._id,
                                }}
                                type="outline"
                                isActive={filterState.tags.includes(tag._id)}
                                onClick={() => {
                                  setFilterState({
                                    ...filterState,
                                    tags: addOrRemove(
                                      filterState.tags,
                                      tag._id
                                    ),
                                  });
                                }}
                              />
                            ))}
                    </HorizontalScrollContainer>
                  );
                })}
            </>
          )}
          {mode === "experience" && (
            <div className="mt-5 mx-4">
              <Slider
                styles={{
                  track: { height: "8px", backgroundColor: "#DAF4F3" },
                  rail: { height: "8px", backgroundColor: "#DAF4F3" },
                  handle: {
                    backgroundColor: "#3E7C76",
                    top: "42%",
                    height: "15px",
                    width: "15px",
                  },
                }}
                min={0}
                max={50}
                value={filterState.experience}
                onChange={(event) => {
                  setFilterState({
                    ...filterState,
                    experience: event as number,
                  });
                }}
              />
              <div className="flex justify-between mx-4 mt-2">
                <div className="text-teal6 font-semibold">0</div>
                <div className="text-teal6 font-semibold">
                  {filterState.experience}+ years
                </div>
                <div className="text-teal6 font-semibold">50</div>
              </div>
            </div>
          )}
          {mode === "efficiency" && (
            <div className="mt-5 mx-4">
              <Slider
                styles={{
                  track: { height: "10px", backgroundColor: "#D3F3D1" },
                  rail: { height: "10px", backgroundColor: "#D3F3D1" },
                  handle: {
                    backgroundColor: "#529A4D",
                    top: "42%",
                    height: "15px",
                    width: "15px",
                  },
                }}
                min={0}
                max={10}
                value={filterState.efficiency}
                onChange={(event) => {
                  setFilterState({
                    ...filterState,
                    efficiency: event as number,
                  });
                }}
              />
              <div className="flex justify-center  mx-4 mt-2">
                <div className="text-green9 font-semibold">
                  {filterState.efficiency}/10
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default FilterSlot;
