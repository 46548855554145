const CarotTwo = ({
  type,
  pathFill,
  classPathFill,
  size = { width: "15", height: "24" },
}: {
  type: "right" | "left";
  pathFill: "black" | "green" | string;
  classPathFill?: string;
  size?: { width: string; height: string };
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 15 24"
    fill="none"
    className={`${type === "left" ? "rotate-180" : ""}`}
  >
    <path
      d="M9.16364 11.9526L0 2.78893L2.78893 0L14.7415 11.9526L2.78893 23.9051L0 21.1162L9.16364 11.9526Z"
      fill={pathFill}
      className={classPathFill ?? ""}
    />
  </svg>
);

export default CarotTwo;
