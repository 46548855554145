import React from "react";
import { useSeniority } from "../../store/seniority.state";
import { SENIORITIES } from "@/constants";

interface SubGroupPillProps {
  name: string;
  className?: string;
  children?: React.ReactNode;
  isActive?: boolean;
}

const SubGroupPill = (props: SubGroupPillProps) => {
  const { activeId: activeSeniorityId } = useSeniority();

  const { name, className, children, isActive } = props;

  const seniority = SENIORITIES.find(
    (seniority_) => seniority_.id === (activeSeniorityId ?? 1)
  )!;

  return (
    <div
      className={` rounded-full px-9 py-2  font-semibold uppercase   ${className} ${
        isActive ? seniority.activeColor : 'bg-[#ffdebb] text-[#df9c28]'
      }`}
    >
      {name}
      <div
        className={`rounded-full ${
          isActive ? seniority.activeCountColor : seniority.countColor
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default SubGroupPill;
