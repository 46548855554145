import { BG_SENIORITY } from "@/constants";
import { ReactElement } from "react";

/**
 * @description This component needs to be wrapped in a parent div that control the  height and width.
 * @param param0
 * @returns
 */
const SmallDoctorCard = ({
  name,
  nickName,
  seniority,
  textSize = "small",
  rightElement,
}: {
  name: string;
  nickName?: string;
  seniority?: number;
  textSize?: "small" | "large";
  rightElement?: ReactElement;
}) => {
  return (
    <div
      className={`bg-white shadow-xl h-full rounded-lg font-semibold p-2 flex items-center relative transition-[width] w-full`}
    >
      <div
        className={`relative -left-2.5 rounded-l-md ${
          BG_SENIORITY[seniority?.toString() as "1" | "2" | "3"] ?? "bg-black3"
        } w-1 h-[80%]`}
      ></div>
      <div
        className={`${
          textSize === "small" ? "text-xs" : "text-sm"
        } text-ellipsis w-[calc(70%)] overflow-hidden whitespace-nowrap`}
      >
        {nickName ?? name}
      </div>
      {rightElement}
    </div>
  );
};

export default SmallDoctorCard;
