const Cursor = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M8.0713 8.0713L12.3141 12.3141M1 1L5.99941 13L7.77431 7.77431L13 5.99941L1 1Z"
        stroke={pathFill}
        stroke-width="1.41426"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Cursor;
