import { ShiftType } from "./interface/shift";

export const TIME_ZONE = "Asia/Kolkata";
export const DISPLAY_DATE_FORMAT = "ccc, dd MMM";

export const DISPLAY_DATE_FORMAT_LONG = "ccc, MMMM yyyy";
export const API_DATE_FORMAT = "dd/MM/yyyy";
export const API_TIME_FORMAT = "h:mm a";

export const DATE_RANGE = 1;

export const CACHE_LIFE = 4 * 3600;

export const SENIORITIES = [
  {
    id: 1,
    colorCode: "#E57041",
    textColor: "text-[#E57041]",
    label: "Senior",
    shortLabel: "Sr",
    bgColor: "bg-[#E57041]",
    bgColorLight: "bg-lightRed1",
    border: "border-[#E57041] border",
    borderNoBottom: "border-[#E57041] border-x-[1px] border-t-[1px]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 2,
    colorCode: "#84ACE2",
    textColor: "text-[#84ACE2]",
    label: "Mid Level",
    shortLabel: "Mid",
    bgColor: "bg-[#84ACE2]",
    bgColorLight: "bg-lightBlue1",
    border: "border-[#84ACE2] border",
    borderNoBottom: "border-[#84ACE2] border-x-[1px] border-t-[1px]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
  {
    id: 3,
    colorCode: "#DF9C28",
    textColor: "text-[#DF9C28]",
    label: "Junior",
    shortLabel: "Jr",
    bgColor: "bg-[#DF9C28]",
    bgColorLight: "bg-yellow6",
    border: "border-[#DF9C28] border",
    borderNoBottom: "border-[#DF9C28] border-x-[1px] border-t-[1px]",
    countColor: "bg-[#df9c28] text-[#ffdebb]",
    activeColor: "bg-[#df9c28] text-white ",
    activeCountColor: "bg-[#FFDEBB] text-[#df9c28]",
  },
];

export const BG_SENIORITY = Object.fromEntries(
  SENIORITIES.map((seniority) => {
    return [seniority.id, `bg-[${seniority.colorCode}]`];
  })
);

export type TimeObject = { hour: number; minute: number; amOrPm: "am" | "pm" };

export const GROUP_COLORS: Record<
  Key,
  {
    bgColor: string;
    bgColor2: string;
    bgColor3: string;
    bgColor4: string;
    bgColor5: string;
    bgColor6: string;
    bgColor7: string;
    bgColor8: string;
    bgColor9: string;
    bgColor10: string;
    bgColor11: string;
    textColor: string;
    textColor2: string;
    textColor3: string;
    textColor4: string;
    textColor5: string;
    strokeColor: string;
    borderColor: string;
    borderColor2: string;
    defaultTimeObject?: {
      from: TimeObject;
      to: TimeObject;
    };
  }
> = {
  morning: {
    bgColor: "bg-green6",
    bgColor2: "bg-green8",
    bgColor3: "bg-yellow3",
    bgColor4: "bg-yellow2",
    bgColor5: "bg-yellow3",
    bgColor6: "bg-yellow4",
    bgColor7: "bg-yellow5",
    bgColor8: "bg-brown",
    bgColor9: "bg-yellow4",
    bgColor10: "bg-yellow1",
    textColor: "text-green7",
    textColor2: "text-yellow2",
    textColor3: "text-yellow3",
    textColor4: "text-yellow5",
    textColor5: "text-brown2",
    strokeColor: "stroke-brown2",
    borderColor: "border-green6",
    borderColor2: "border-brown2",
    bgColor11: "bg-brown2",
    defaultTimeObject: {
      from: { hour: 9, minute: 30, amOrPm: "am" },
      to: { hour: 11, minute: 30, amOrPm: "am" },
    },
  },
  noon: {
    bgColor: "bg-teal4",
    bgColor2: "bg-teal5",
    bgColor3: "bg-green6",
    bgColor4: "bg-green14",
    bgColor5: "bg-green15",
    bgColor6: "bg-lightGreen6",
    bgColor7: "bg-green16",
    bgColor8: "bg-green17",
    bgColor9: "bg-green19",
    bgColor10: "bg-green6",
    textColor: "text-teal4",
    textColor2: "text-green14",
    textColor3: "text-green6",
    textColor4: "text-green16",
    textColor5: "text-green4",
    strokeColor: "stroke-green4",
    borderColor: "border-teal4",
    borderColor2: "border-green4",
    bgColor11: "bg-green4",
    defaultTimeObject: {
      from: { hour: 2, minute: 30, amOrPm: "pm" },
      to: { hour: 4, minute: 30, amOrPm: "pm" },
    },
  },
  night: {
    bgColor: "bg-blue1",
    bgColor2: "bg-blue5",
    bgColor3: "bg-blue6",
    bgColor4: "bg-blue1",
    bgColor5: "bg-blue7",
    bgColor6: "bg-blue8",
    bgColor7: "bg-blue9",
    bgColor8: "bg-blue10",
    bgColor9: "bg-blue8",
    bgColor10: "bg-blue2",
    textColor: "text-blue1",
    textColor2: "text-blue1",
    textColor3: "text-blue6",
    textColor4: "text-blue9",
    textColor5: "text-blue1",
    strokeColor: "stroke-blue1",
    borderColor: "border-blue1",
    borderColor2: "border-blue1",
    bgColor11: "bg-blue1",
    defaultTimeObject: {
      from: { hour: 9, minute: 30, amOrPm: "pm" },
      to: { hour: 11, minute: 30, amOrPm: "pm" },
    },
  },
  standBy: {
    bgColor: "bg-secondary",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    bgColor5: "",
    bgColor6: "bg-red5",
    bgColor7: "",
    bgColor8: "",
    bgColor9: "bg-red3",
    bgColor10: "bg-red6",
    textColor: "text-white",
    textColor2: "",
    textColor3: "",
    textColor4: "",
    textColor5: "text-red4",
    strokeColor: "",
    borderColor: "border-secondary",
    borderColor2: "border-red4",
    bgColor11: "bg-red4",
    defaultTimeObject: {
      from: { hour: 9, minute: 30, amOrPm: "am" },
      to: { hour: 11, minute: 30, amOrPm: "am" },
    },
  },
  dayOff: {
    bgColor: "bg-lightGreen2",
    bgColor2: "",
    bgColor3: "",
    bgColor4: "",
    bgColor5: "",
    bgColor6: "",
    bgColor7: "",
    bgColor8: "",
    bgColor9: "",
    bgColor10: "",
    textColor: "text-white",
    textColor2: "",
    textColor3: "",
    textColor4: "",
    textColor5: "",
    strokeColor: "",
    borderColor: "border-lightGreen2",
    borderColor2: "",
    bgColor11: "",
  },
};

// Table Page

export type TimeBlock = "morning" | "noon" | "night";

export type Key = TimeBlock | "standBy" | "dayOff";

export const ROW_HEIGHT = 45;

export const ROW_WIDTH: Record<
  "location" | "requests" | "users",
  { className: string; value: number }
> = {
  users: { className: "w-[135px]", value: 135 },
  location: { className: "w-[155px]", value: 155 },
  requests: { className: "w-[135px]", value: 135 },
};

export const SHIFT_TYPE: Record<
  "standBy" | "onCall",
  { label: string; colorCode: string }
> = {
  standBy: { label: "SB", colorCode: "#1971C2" },
  onCall: { label: "OC", colorCode: "#F08C00" },
};

// Only For Shifts
export const TIMING_LIST: {
  label: string;
  key: Key;
  type: ShiftType;
  time?: {
    from: { hour: number; min?: number };
    to: { hour: number; min?: number };
  }[];
}[] = [
  {
    key: "morning",
    label: "Morning",
    type: "normal",
    time: [{ from: { hour: 6 }, to: { hour: 11, min: 59 } }],
  },
  {
    key: "noon",
    label: "Noon",
    type: "normal",
    time: [{ from: { hour: 12 }, to: { hour: 17, min: 59 } }],
  },
  {
    key: "night",
    label: "Night",
    type: "normal",
    time: [
      {
        from: { hour: 18 },
        to: { hour: 23, min: 59 },
      },
      {
        from: { hour: 0 },
        to: { hour: 5, min: 59 },
      },
    ],
  },
  {
    key: "standBy",
    label: "Stand\nBy",
    type: "standBy",
  },
];
