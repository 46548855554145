const Table = ({
  pathFill,
  rectFill,
  size = { height: "20px", width: "24px" },
}: {
  pathFill: string;
  rectFill: string;
  size?: { height: string; width: string };
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 24 20"
      fill="none"
    >
      <rect width="24" height="20" rx="4" fill={rectFill} />
      <path
        d="M9 16H20V13.325H9V16ZM4 6.675H7V4H4V6.675ZM4 11.35H7V8.675H4V11.35ZM4 16H7V13.325H4V16ZM9 11.35H20V8.675H9V11.35ZM9 6.675H20V4H9V6.675ZM4 18C3.45 18 2.97917 17.8042 2.5875 17.4125C2.19583 17.0208 2 16.55 2 16V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H4Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Table;
