import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const dayOffApi = createApi({
  reducerPath: "dayOffApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getDayOffs: builder.query<any, any>({
      query: ({ date }) => ({
        url: `/day-off?date=${date}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getDayOffsByMonth: builder.query<any, any>({
      query: ({ month, year }) => ({
        url: `/day-off/month?month=${month}&year=${year}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const { useGetDayOffsQuery, useGetDayOffsByMonthQuery} = dayOffApi;
