const DualArrow = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M4.27936 13.8619L0 9.58257L4.27936 5.30321L5.47759 6.52282L3.27371 8.7267H9.4146V10.4384H3.27371L5.47759 12.6423L4.27936 13.8619ZM12.8381 8.7267L11.6399 7.50708L13.8437 5.30321H7.70286V3.59146H13.8437L11.6399 1.38759L12.8381 0.167969L17.1175 4.44733L12.8381 8.7267Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default DualArrow;
