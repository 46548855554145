import { useState } from "react";
import { Shift } from "@/interface/shift";
import { useRunOnChangeExt } from "@/hooks/runOnChange";
import ShiftCell from "./ShiftCell";

const Cell = ({
  isActive,
  shifts,
  isOff,
  slotIndexes,
  isTimeShowExt,
  isFetching,
  doctorSeniority,
}: {
  isActive: boolean;
  shifts: Shift[];
  slotIndexes: number[];
  isOff: boolean;
  isTimeShowExt: boolean;
  isFetching: boolean;
  doctorSeniority: number;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTimeShowInt, setIsTimeShowInt] = useState(false);

  useRunOnChangeExt(isTimeShowExt, () => {
    setIsTimeShowInt(isTimeShowExt);
  });

  return (
    <div
      className={`h-full w-full ${isActive ? "bg-white" : "bg-gray8"} ${
        isFetching ? "animate-pulseFast" : ""
      } flex justify-center items-center relative`}
    >
      {isFetching ? (
        <div className="w-[70%] h-[70%] bg-gray3 rounded-lg"></div>
      ) : (
        <>
          {shifts.length > 0 && !isOff && (
            <>
              <ShiftCell
                isActive={isActive}
                setIsTimeShow={setIsTimeShowInt}
                shift={shifts[activeIndex]}
                slotIndex={slotIndexes[activeIndex]}
                isMultiple={shifts.length > 1}
                onPrevShift={() => {
                  if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1);
                  } else {
                    setActiveIndex(shifts.length - 1);
                  }
                }}
                onNextShift={() => {
                  if (activeIndex < shifts.length - 1) {
                    setActiveIndex(activeIndex + 1);
                  } else {
                    setActiveIndex(0);
                  }
                }}
                isTimeShow={isTimeShowInt}
                seniority={
                  doctorSeniority !== shifts[activeIndex].seniority.id
                    ? shifts[activeIndex].seniority.id
                    : undefined
                }
              />
              {shifts.length > 1 && (
                <div className="flex justify-center mt-1 gap-[1px] absolute bottom-[2px]">
                  {Array(shifts.length)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        key={index}
                        className={`w-[5px] h-[5px] rounded-full ${
                          index === activeIndex ? "bg-lightGreen5" : "bg-gray9"
                        }`}
                      ></div>
                    ))}
                </div>
              )}
            </>
          )}
          {isOff && (
            <div
              className={`rounded-l-full rounded-r-full ${
                isActive ? "bg-maroon1 text-white" : "bg-maroon3 text-black5"
              } px-[10px] py-[2px] font-medium text-center drop-shadow-2xl`}
            >
              Off
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Cell;
