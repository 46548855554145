export default function Plus({
  color,
  stroke = "",
  className = "",
  size = { width: "20px", height: "20px" },
}: {
  color: "white" | "black" | "gray" | "secondary";
  stroke?: string;
  className?: string;
  size?: { width: string; height: string };
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width={size.width}
        height={size.height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <g id="Frame">
          <path
            id="Vector"
            d="M10 15.625V4.375M4.375 10H15.625"
            stroke={color}
            className={stroke}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
}
