const CarotWide = ({
  type,
  pathFill,
  classPathFill,
  size = { width: "14", height: "32" },
}: {
  type: "right" | "left";
  pathFill:string
  classPathFill: string;
  size?: { width: string; height: string };
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 14 32"
    fill="none"
    className={`${type === "right" ? "rotate-180" : ""}`}
  >
    <path
      d="M5.375 16.25L14 28.325L11.375 32L0.125001 16.25L11.375 0.5L14 4.175L5.375 16.25Z"
      fill={pathFill}
      className={classPathFill}
    />
  </svg>
);

export default CarotWide;
