import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";

export const generateDatesOffset = (date: Date, offset: number) => {
  return Array(offset * 2 + 1)
    .fill(0)
    .map((_, index) =>
      DateTime.fromJSDate(date)
        .setZone(TIME_ZONE)
        .plus({ day: -1 * offset + index })
        .toJSDate()
    );
};

export const generateDatesMonth = (month: number, year: number) => {
  const dateTime = DateTime.fromObject(
    { day: 1, month, year },
    { zone: TIME_ZONE }
  );
  return Array(dateTime.daysInMonth)
    .fill(0)
    .map((_, index) =>
      DateTime.fromObject(
        { day: index + 1, month, year },
        { zone: TIME_ZONE }
      ).toJSDate()
    );
};
