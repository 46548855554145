const CalendarRound3 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.1111 1.39587V5.18754M6.8056 1.39587V5.18754M1.61462 8.97921H20.3021M3.69101 3.29171H18.2257C19.3725 3.29171 20.3021 4.1405 20.3021 5.18754V18.4584C20.3021 19.5054 19.3725 20.3542 18.2257 20.3542H3.69101C2.54425 20.3542 1.61462 19.5054 1.61462 18.4584V5.18754C1.61462 4.1405 2.54425 3.29171 3.69101 3.29171Z"
        stroke="#545454"
        stroke-width="2.02575"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CalendarRound3;
