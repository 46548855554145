const HalfArrow = ({
  pathFill,
  type,
}: {
  pathFill: string;
  type: "up" | "down";
}) => {
  if (type === "down") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3"
        height="10"
        viewBox="0 0 3 10"
        fill="none"
      >
        <line x1="2.5" x2="2.5" y2="9" stroke={pathFill} />
        <line
          x1="2.55279"
          y1="9.22361"
          x2="0.552786"
          y2="5.22361"
          stroke={pathFill}
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3"
        height="10"
        viewBox="0 0 3 10"
        fill="none"
      >
        <line x1="0.5" y1="10" x2="0.500001" y2="1" stroke={pathFill} />
        <line
          x1="0.447214"
          y1="0.776393"
          x2="2.44721"
          y2="4.77639"
          stroke={pathFill}
        />
      </svg>
    );
  }
};

export default HalfArrow