const Eraser = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M13.0615 13.5863H17.1269V15.298H11.3497L13.0615 13.5863ZM2.36304 15.298L0.544313 13.4793C0.216228 13.1512 0.0486197 12.7447 0.0414874 12.2597C0.0343551 11.7747 0.194831 11.361 0.522916 11.0186L9.93752 1.26169C10.2656 0.919344 10.6686 0.748169 11.1464 0.748169C11.6243 0.748169 12.0273 0.912211 12.3554 1.2403L16.6133 5.49826C16.9414 5.82635 17.1055 6.23289 17.1055 6.71788C17.1055 7.20288 16.9414 7.60942 16.6133 7.9375L9.424 15.298H2.36304ZM8.6965 13.5863L15.4151 6.69649L11.1785 2.45991L1.72114 12.2169L3.09053 13.5863H8.6965Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default Eraser