import { Shift, ShiftType } from "@/interface/shift";
import useGroupShifts from "./hooks/useGroupShifts";
import ShiftCard from "./ShiftCard";
import { DateTime } from "luxon";
import { GROUP_COLORS, Key, TimeBlock, TIMING_LIST } from "@/constants";
import { useState } from "react";
import CarotTwo from "../Icons/CarotVert";
import Plus from "../Icons/Plus";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

const ShiftsColumn = ({ shifts, date }: { shifts: Shift[]; date: Date }) => {
  const groupedShifts = useGroupShifts({ shifts, dayOff: { doctors: [] } });
  const { timeBlockFilters } = useTableViewContext();

  const [groupState, setGroupState] = useState<
    Record<Key, { list: "close" | "open"; isAdd: boolean }>
  >(
    Object.fromEntries(
      TIMING_LIST.map((tB) => {
        return [tB.key, { list: "open", isAdd: false }];
      })
    ) as Record<Key, { list: "close" | "open"; isAdd: boolean }>
  );

  return (
    <div className="flex flex-col gap-2 py-1">
      {TIMING_LIST.filter((tO) => {
        return (
          !["morning", "noon", "night"].includes(tO.key) ||
          (["morning", "noon", "night"].includes(tO.key) &&
            timeBlockFilters.includes(tO.key as TimeBlock))
        );
      }).map((tO) => {
        const shifts = groupedShifts.find((gS) => gS.key === tO.key)?.shifts;
        return (
          <div
            className={`${GROUP_COLORS[tO.key].bgColor9} px-2 ${
              groupState[tO.key].list === "open" ? "pb-2" : ""
            } rounded-lg`}
          >
            <div
              className={`w-full flex justify-center items-center font-medium text-sm ${
                GROUP_COLORS[tO.key].textColor5
              } relative`}
            >
              {shifts && shifts.length > 0 && (
                <div
                  className="absolute left-3 cursor-pointer"
                  onClick={() => {
                    setGroupState({
                      ...groupState,
                      [tO.key]:
                        groupState[tO.key].list === "close"
                          ? { ...groupState[tO.key], list: "open" }
                          : {
                              ...groupState[tO.key],
                              list: "close",
                              isAdd: false,
                            },
                    });
                  }}
                >
                  <CarotTwo
                    type={groupState[tO.key].list === "open" ? "up" : "down"}
                    pathFill={"black"}
                  />
                </div>
              )}
              <div className="my-1">{tO.label}</div>
              <div
                className="absolute right-3 cursor-pointer"
                onClick={() => {
                  setGroupState({
                    ...groupState,
                    [tO.key]: groupState[tO.key].isAdd
                      ? { ...groupState[tO.key], isAdd: false }
                      : { ...groupState[tO.key], isAdd: true, list: "open" },
                  });
                }}
              >
                <Plus color={"black"} />
              </div>
            </div>
            {groupState[tO.key].list === "open" && (
              <div className="flex flex-col gap-2">
                {groupState[tO.key].isAdd && (
                  <ShiftCard
                    isFetching={false}
                    date={date}
                    default_={{
                      time: GROUP_COLORS[tO.key].defaultTimeObject!,
                      type: ["morning", "noon", "night"].includes(tO.key)
                        ? "normal"
                        : (tO.key as ShiftType),
                    }}
                    groupKey={tO.key}
                    onClose={() => {
                      setGroupState({
                        ...groupState,
                        [tO.key]: { ...groupState[tO.key], isAdd: false },
                      });
                    }}
                  />
                )}
                {shifts?.map((shift) => (
                  <ShiftCard
                    groupKey={tO.key}
                    shiftData={{
                      time: shift.time,
                      type: shift.type,
                      slots: shift.slots,
                      _id: shift._id,
                    }}
                    isFetching={false}
                    date={DateTime.fromISO(shift.date).toJSDate()}
                  />
                )) ?? []}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShiftsColumn;
