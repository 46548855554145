const ChatBubbleStar = ({
  pathFill,
  width = "10",
  height = "10",
}: {
  pathFill: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M3.5375 6.125L5 5.2375L6.4625 6.125L6.075 4.4625L7.375 3.3375L5.6625 3.2L5 1.625L4.3375 3.2L2.625 3.3375L3.925 4.4625L3.5375 6.125ZM0 10V1C0 0.725 0.0979167 0.489583 0.29375 0.29375C0.489583 0.0979167 0.725 0 1 0H9C9.275 0 9.51042 0.0979167 9.70625 0.29375C9.90208 0.489583 10 0.725 10 1V7C10 7.275 9.90208 7.51042 9.70625 7.70625C9.51042 7.90208 9.275 8 9 8H2L0 10ZM1.575 7H9V1H1V7.5625L1.575 7Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default ChatBubbleStar