import React, { useState, MouseEvent, RefObject } from "react";
import TagPill from "./Pills/TagPill";

interface horizontalScrollContainerI {
  children: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  containerRef?: RefObject<HTMLDivElement>;
}

const HorizontalScrollContainer: React.FC<horizontalScrollContainerI> = ({
  children,
  isLoading = false,
  className,
  containerRef,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the multiplier for faster or slower scrolling
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={
        "flex gap-x-4 items-center overflow-x-hidden whitespace-nowrap cursor-grab select-none relative " +
        className
      }
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      {...(containerRef ? { ref: containerRef } : {})}
    >
      {!isLoading
        ? children
        : Array(3)
            .fill(undefined)
            .map((_, index) => (
              <TagPill
                key={index}
                name={""}
                className={
                  "flex gap-x-4 items-center animate-pulseFast h-[28px] w-[100px]"
                }
                isActive={false}
                activeBgColor=""
              />
            ))}
    </div>
  );
};

export default HorizontalScrollContainer;
