import { PropsWithChildren, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { useDebounce } from "use-debounce";

import Colon from "../../Icons/Colon";
import InfoIcon from "../../Icons/InfoIcon";
import { TIME_ZONE } from "../../../constants";

const tabs = {
  "0": { label: "Work Days", key: "workDayCount" },
  "1": { label: "Off Days", key: "dayOffCount" },
  "2": { label: "ARP", key: "arp" },
};

const CardContainer = ({
  children,
  className,
  motionKey,
}: PropsWithChildren<{ className?: string; motionKey: string }>) => (
  <motion.div
    key={motionKey}
    initial={{ opacity: 0, zIndex: 0 }}
    animate={{ opacity: 1, zIndex: 10 }}
    exit={{ opacity: 0, zIndex: 0 }}
    transition={{ type: "interia", duration: 1 }}
    className={`absolute rounded-3xl h-full w-full px-2 pt-4 pb-2.5 flex flex-col ${
      className ?? ""
    }`}
  >
    {children}
  </motion.div>
);

const UpcomingShiftCard = ({
  location,
  selectedSchedule,
  isLoading,
  stat,
  isDayOff,
}: {
  isDayOff: boolean;
  location: string;
  isLoading: boolean;
  selectedSchedule: any;
  stat: any | undefined;
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const buttonNavColors = [
    "bg-orange2",
    "bg-[#16667B]",
    "bg-[#A19B46]",
    "bg-[#FE7862]",
  ];

  const textNavColors = [
    "text-orange2",
    "text-[#16667B]",
    "text-[#A19B46]",
    "text-[#FE7862]",
  ];

  const infoCardColors = [
    {
      range: "8.5 - 10",
      label: "Burnt Out",
      bgColor: "!bg-[#FE7862]",
      hexColor: "#FE7862",
      textColor: "text-[#FE7862]",
    },
    {
      range: "7.0 - 8.49",
      label: "Exhausted",
      bgColor: "!bg-[#DF9C28]",
      hexColor: "#DF9C28",
      textColor: "text-[#DF9C28]",
    },
    {
      range: "5.5 - 6.99",
      label: "Tired",
      bgColor: "!bg-[#F2E99C]",
      hexColor: "#F2E99C",
      textColor: "text-[#F2E99C]",
    },
    {
      range: "4.0 - 5.49",
      label: "Twilight zone",
      bgColor: "!bg-[#84ACE2]",
      hexColor: "#84ACE2",
      textColor: "text-[#84ACE2]",
    },
    {
      range: "2.5 - 3.99",
      label: "Rested",
      bgColor: "!bg-[#ABD3B6]",
      hexColor: "#ABD3B6",
      textColor: "text-[#ABD3B6]",
    },
    {
      range: "<2.5",
      label: "Well rested",
      bgColor: "!bg-[#67823A]",
      hexColor: "#67823A",
      textColor: "text-[#67823A]",
    },
  ];

  const [showInfo, setShowInfo] = useState(false);

  function getRestLevelValue(val: any) {
    if (val >= 0 && val < 2.5) {
      return infoCardColors[5];
    }
    if (val >= 2.5 && val <= 3.99) {
      return infoCardColors[4];
    }
    if (val >= 4 && val <= 5.49) {
      return infoCardColors[3];
    }
    if (val >= 5.5 && val <= 6.99) {
      return infoCardColors[2];
    }
    if (val >= 7.0 && val <= 8.49) {
      return infoCardColors[1];
    }
    if (val >= 8.5 && val <= 10) {
      return infoCardColors[0];
    }
  }

  const infoItem = [
    <CardContainer
      motionKey="info-0"
      className="bg-[#303030] font-medium text-[12px] text-[#FFFFFF]"
    >
      ARP: Average Rest Period refers to the average rest this user gets between
      consecutive shifts for the current month. Sleep day hours will count, but
      off day blocks will be omitted.
    </CardContainer>,
    <CardContainer
      motionKey="info-1"
      className="bg-[#303030] font-medium text-[12px] text-[#FFFFFF]"
    >
      {infoCardColors.map((d, index) => (
        <div className="flex items-center gap-1 flex-grow" key={index}>
          <div className={`w-2 h-2 rounded-[1.5px] ${d.bgColor}`}></div>
          <div className={`text-[10px] font-medium ${d.textColor}`}>
            {d.range} {d.label}
          </div>
        </div>
      ))}
    </CardContainer>,
  ];

  const progressStyles = buildStyles({
    rotation: -0.25,
    strokeLinecap: "butt",
    pathColor: getRestLevelValue(8)?.hexColor,
  });

  const [toggleInfoPopover] = useDebounce((): void => {
    setShowInfo(!showInfo);
  }, 5);

  const restLevel = 0;

  const dateTime = selectedSchedule?.shift
    ? DateTime.fromISO(selectedSchedule.shift.time.from).setZone(
        TIME_ZONE
      )
    : undefined;

  const scheduleDuration = selectedSchedule?.shift
    ? Math.round(
        DateTime.fromISO(selectedSchedule.shift.time.to)
          .setZone(TIME_ZONE)
          .diff(
            DateTime.fromISO(selectedSchedule.shift.time.from).setZone(
              TIME_ZONE
            ),
            "hours"
          ).hours
      )
    : undefined;

  return (
    <div className="relative w-full h-full p-4 space-y-0 bg-white border-[0.3px] border-[#e0e0e0] rounded-xl ">
      <div className="relative h-[40%] flex flex-col">
        {!isLoading ? (
          <>
            {activeIndex == 3 || activeIndex == 2 ? (
              <button
                onClick={() => toggleInfoPopover()}
                className="absolute right-0 top-0"
              >
                <InfoIcon
                  className=""
                  strokeClass={`${showInfo ? "stroke-black" : "stroke-gray2"}`}
                />
              </button>
            ) : null}
            <div
              className={`w-fit flex-grow flex items-center mx-auto font-gotham font-medium ${textNavColors[activeIndex]} text-[56px] pt-3 overflow-hidden transition-all duration-500`}
            >
              {activeIndex != 3 ? (
                <div className="flex items-center leading-[60px] mb-2">
                  {stat?.[
                    tabs[activeIndex.toString() as "0" | "1" | "2"].key
                  ] ?? "NA"}
                  <div className="text-[16px] w-12 leading-5 text-center">
                    {tabs[activeIndex.toString() as "0" | "1" | "2"].label}
                  </div>
                </div>
              ) : (
                <div className="text-base w-[40.7%] mx-auto">
                  <CircularProgressbarWithChildren
                    circleRatio={0.5}
                    value={restLevel * 10}
                    strokeWidth={12}
                    styles={{
                      ...progressStyles,
                    }}
                  >
                    <div className="text-center mb-[10px] space-y-3">
                      <div
                        className={`mb-[2px] text-[30px] ${
                          getRestLevelValue(restLevel)?.textColor
                        }`}
                      >
                        {restLevel}
                      </div>
                      <div
                        className={`text-sm leading-3 ${
                          getRestLevelValue(restLevel)?.textColor
                        }`}
                      >
                        {getRestLevelValue(restLevel)?.label}
                      </div>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )}
            </div>
            {!showInfo && (
              <div className={`space-x-2 mb-3 flex`}>
                {Array.from({ length: 4 }).map((_, value) => (
                  <button
                    onClick={() => {
                      setActiveIndex(value);
                      setShowInfo(false);
                    }}
                    className={`h-1 rounded ${
                      value == activeIndex && activeIndex != 3
                        ? buttonNavColors[value]
                        : "bg-[#E0E0E0]"
                    } ${
                      value == activeIndex && activeIndex == 3
                        ? getRestLevelValue(restLevel)?.bgColor
                        : ""
                    }`}
                    style={{ width: `${100 / 4}%` }}
                    key={value}
                  ></button>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="flex-grow flex justify-center">
            <InfinitySpin width="200" color="#4fa94d" />
          </div>
        )}
      </div>
      <div className="relative h-[60%] w-full">
        <AnimatePresence>
          {showInfo && activeIndex == 2
            ? infoItem[0]
            : showInfo && activeIndex == 3
            ? infoItem[1]
            : null}
          {!showInfo && (
            <CardContainer
              motionKey="upcoming-shift"
              className={`bg-[url('/images/shiftcard.png')] bg-no-repeat bg-cover flex ${
                selectedSchedule ? "flex-col justify-between" : "justify-center"
              } items-center `}
            >
              {selectedSchedule && (
                <>
                  <div className="h-[60%] flex items-center">
                    <div>
                      <div className="text-[50px] h-fit w-fit mx-auto leading-3 relative flex items-center font-bold text-[#2C5339]">
                        {selectedSchedule?.schedule?.shift.type ===
                        "standBy" ? (
                          "Stand By"
                        ) : (
                          <>
                            {dateTime!.toFormat("hh")}
                            <Colon />
                            {dateTime!.toFormat("mm")}
                            <div className="top-0 font-medium uppercase text-[7px] text-white bg-[#2C5339] p-[0.5px] rounded-[2px] -mt-3">
                              {dateTime!.toFormat("a")}
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className="text-center pt-2 text-[#97A56A] font-medium text-[11px] uppercase mt-1"
                        style={{ letterSpacing: "0.83px" }}
                      >
                        {dateTime!.toFormat("EEEE, d MMMM")}
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#2C5339] rounded-xl w-full h-[40%] flex px-3.5 py-1.5 ">
                    <div className="flex items-center justify-center w-[65%]">
                      <div className="text-white font-medium text-[28px]">
                        {scheduleDuration}
                      </div>
                      <div className="font-medium text-white text-[13px] uppercase ml-1">
                        Hours
                      </div>
                    </div>
                    <div className="h-1/3 my-auto w-[0.5px] bg-white"></div>
                    <div className="flex items-center w-[35%]">
                      <div className="font-medium text-white text-[13px] uppercase w-full text-center">
                        {location}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!selectedSchedule && (
                <div className="text-[#97A56A] m-auto font-medium text-2xl text-center">
                  {isDayOff ? "Day Off" : "No Shift Assigned"}
                </div>
              )}
            </CardContainer>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default UpcomingShiftCard;
