import { useMemo } from "react";

import { GroupedDoctors } from "../DayView";
import { Shift, ShiftType } from "../../../interface/shift";
import { TIME_ZONE, TIMING_LIST } from "../../../constants";
import { DateTime } from "luxon";
import { Doctor } from "@/interface/doctor";

const useGroupShifts = ({
  shiftsByTiming,
  dayOff,
}: {
  shiftsByTiming: {
    time: { from: string; to: string };
    type: ShiftType;
    shifts: Shift[];
  }[];
  dayOff: { doctors: Doctor[] };
}): {
  groupedShifts: GroupedDoctors;
  timeBlockSummary: Record<
    string,
    { percentage: number; totalSlots: number; doctorsFilled: number }
  >;
} => {
  return useMemo(() => {
    const innerGroupedShifts: GroupedDoctors = [];
    const precentageMap: Record<
      string,
      { totalSlots: number; doctorsFilled: number }
    > = {};

    if (shiftsByTiming && dayOff) {
      TIMING_LIST.forEach((timeBlock) => {
        shiftsByTiming.forEach((timingGroup) => {
          const index = innerGroupedShifts.findIndex(
            (gS) => gS.key === timeBlock.key
          );
          if (
            timingGroup.type === timeBlock.type &&
            timingGroup.shifts.some((shift) =>
              shift.slots.some((slot) => slot.schedule)
            )
          ) {
            if (timeBlock.type !== "normal") {
              if (index !== -1) {
                innerGroupedShifts[index].doctors?.push(
                  ...timingGroup.shifts
                    .map((shift) =>
                      shift.slots
                        .filter((slot) => Boolean(slot.schedule))
                        .map((slot) => ({
                          doctor: slot.schedule.doctor,
                          location: shift.location,
                        }))
                    )
                    .flat()
                );
              } else {
                innerGroupedShifts.push({
                  doctors: timingGroup.shifts
                    .map((shift) =>
                      shift.slots
                        .filter((slot) => Boolean(slot.schedule))
                        .map((slot) => ({
                          doctor: slot.schedule.doctor,
                          location: shift.location,
                        }))
                    )
                    .flat(),
                  label: timeBlock.label,
                  key: timeBlock.key,
                });
              }
            } else if (timeBlock.type === "normal") {
              const fromTime = DateTime.fromISO(timingGroup.time.from).setZone(
                TIME_ZONE
              );

              if (
                timeBlock.time!.some(
                  (t) =>
                    fromTime >=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.from.hour,
                          minute: t.from.min,
                        },
                        { zone: TIME_ZONE }
                      ) &&
                    fromTime <=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.to.hour,
                          minute: t.to.min,
                        },
                        { zone: TIME_ZONE }
                      )
                )
              ) {
                const obj = timingGroup.shifts.reduce(
                  (map, shift) => {
                    return {
                      totalSlots: map.totalSlots + shift.totalDoctorsRequired,
                      doctorsFilled: map.doctorsFilled + shift.doctorsFilled,
                    };
                  },
                  { totalSlots: 0, doctorsFilled: 0 }
                );

                if (precentageMap[timeBlock.key]) {
                  precentageMap[timeBlock.key].totalSlots += obj.totalSlots;
                  precentageMap[timeBlock.key].doctorsFilled +=
                    obj.doctorsFilled;
                } else {
                  precentageMap[timeBlock.key] = obj;
                }

                if (index !== -1) {
                  innerGroupedShifts[index].timeGroups?.push(timingGroup);
                } else {
                  innerGroupedShifts.push({
                    timeGroups: [timingGroup],
                    label: timeBlock.label,
                    key: timeBlock.key,
                  });
                }
              }
            }
          }
        });
      });

      if (dayOff?.doctors.length > 0) {
        innerGroupedShifts.push({
          doctors: dayOff.doctors.map((doctor) => ({
            doctor,
          })),
          label: "Day Off",
          key: "dayOff",
        });
      }
    }

    return {
      groupedShifts: innerGroupedShifts,
      timeBlockSummary: Object.fromEntries(
        Object.entries(precentageMap).map(([key, obj]) => [
          key,
          {
            ...obj,
            percentage: Math.floor((100 * obj.doctorsFilled) / obj.totalSlots),
          },
        ])
      ),
    };
  }, [shiftsByTiming, dayOff?.doctors]);
};

export default useGroupShifts;
