const Menu = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.480841"
      height="21.39752"
      viewBox="0 0 22.480841 21.39752"
      fill="none"
      version="1.1"
      id="svg1"
    >
      <defs id="defs1" />
      <path
        d="M 0.94874501,17.195875 V 4.201585 c 0,-1.79649 1.45634999,-3.25283999 3.25283999,-3.25283999 h 14.07761 c 1.7965,0 3.2529,1.45634999 3.2529,3.25283999 v 12.99429 c 0,1.7965 -1.4564,3.2529 -3.2529,3.2529 H 4.201575 c -1.79649,0 -3.25282999,-1.4564 -3.25282999,-3.2529 z"
        stroke={pathFill}
        stroke-width="1.89749"
        id="path1"
      />
      <path
        d="M 5.1348062,5.3612542 H 17.484759 M 5.1348062,10.639454 H 17.484759 M 5.1348062,15.917694 H 17.484759"
        stroke={pathFill}
        stroke-width="1.89749"
        id="path1-9"
      />
    </svg>
  );
};

export default Menu;
