const CrossTwo = ({ pathFill }: { pathFill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <path
        d="M2.38443 0.669091L4.00239 3.52318L5.63466 0.669091H7.19534L4.9092 4.33455L7.21443 8H5.65375L4.00239 5.26045L2.3558 8H0.790341L3.0717 4.33455L0.818977 0.669091H2.38443Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default CrossTwo;
