import { FC, PropsWithChildren } from "react";
const TableViewLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="h-screen w-screen flex flex-col inter-font"
    >
      {children}
    </div>
  );
};

export default TableViewLayout;
