const CarotVert = ({
  type,
  pathFill,
  classPathFill,
  size = { width: "15", height: "24" },
}: {
  type: "up" | "down";
  pathFill: string;
  classPathFill?: string;
  size?: { width: string; height: string };
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 16 8"
    fill="none"
    className={`${type === "down" ? "rotate-180" : ""}`}
  >
    <path
      d="M14.5713 7L7.71415 0.999999L0.857003 7"
      stroke={pathFill}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CarotVert;
